import React from "react";

import { Col, Row } from "reactstrap";
import Folder from "./Folder";
import File from "./File";

const FileList = (props: any) => {
  const {
    files,
    folders,
    setParentFolderId,
    setPdf,
    setOpenModal,
    setPdfData,
    setFolderId,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>
                {/* My Files */}
                ملفاتي
              </h5>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {folders &&
            folders.map((folder, key: number) => {
              return (
                <Folder
                  folder={folder}
                  key={key}
                  setParentFolderId={setParentFolderId}
                />
              );
            })}

          {files &&
            files.map((file, key: number) => {
              return (
                <File
                  file={file}
                  key={key}
                  setPdf={setPdf}
                  setPdfData={setPdfData}
                  setOpenModal={setOpenModal}
                  setFolderId={setFolderId}
                />
              );
            })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default FileList;
