import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap'

// import images
import logo from '../../assets/images/logo.svg'
import { useDispatch } from 'react-redux'
import { forgotPassword } from 'slices/thunk'

const Recoverpw = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState<boolean>(false)
  //meta title
  document.title = 'Recover Password | ESignature'

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('الرجاء ادخال البريد الإلكتروني'
        // 'Please Enter Your Email'
        
        ),
    }),
    onSubmit: async (values: any) => {
      try {
        setLoading(true)
        const response = await dispatch(forgotPassword(values))

        setLoading(false)
        if (response.type === 'user/forgotPassword/fulfilled') {
          navigate('/reset-password')
        }
      } catch (error) {
        setLoading(false)
        console.log('Error while sending mail : ', error)
      }
    },
  })

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary-subtle-self">
                    <Row>
                      <Col xs={7}>
                        <div className="bg-layout-text p-4">
                          <h5 className="bg-layout-text"> 
                          {/* Reset Password */}
                          إعادة تعيين كلمة المرور
                          </h5>
                          <p>
                          ESignature  إعادة تعيين كلمة المرور مع 
                            </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/dashboard">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="p-2">
                      <div
                        className="alert alert-success text-center mb-4"
                        role="alert">
                        {' '}
                        {/* Enter your Email and instructions will be sent to you!{' '} */}
                        أدخل بريدك الإلكتروني وسيتم إرسال التعليمات إليك {' '}
                      </div>

                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {/* Email */}
                            الايميل
                            </Label>
                          <Input
                            name="email"
                            className="form-control"
                            // placeholder="Enter email"
                            placeholder='ادخل البريد الإلكتروني'
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit">
                            {/* Reset */}
                            إعادة ضبط
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {/* Remember It ?{' '} */}
                    تذكر ذلك ؟
                    <Link to="/login" className="fw-medium text-primary">
                      {' '}
                      {/* Sign In here */}
                      تسجيل الدخول هنا
                    </Link>{' '}
                  </p>
                  {/* <p>© {new Date().getFullYear()} ESignature</p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default Recoverpw
