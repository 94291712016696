import { createSlice } from "@reduxjs/toolkit";

import { registerUser } from "./thunk";

export const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isUserLogout: true,
};

const registerSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state, { payload }) => {
   
      state.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
     

      state.registrationError = null;
      state.message = payload.message;
      state.loading = false;
      state.user = payload;
      state.success = true;
      state.error = false;
      state.isUserLogout = false;
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
    

      state.loading = false;
      state.success = false;
      state.error = true;
      state.isUserLogout = true;
    });
  },
});

// export const {} = registerSlice.actions;

export default registerSlice.reducer;
