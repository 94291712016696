import { combineReducers } from 'redux'

import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
// Front
// import LayoutReducer from "./layouts/reducer";

import DashboardReducer from './dashboards/reducer'
import LayoutReducer from './layouts/reducer'
import LoginReducer from './auth/login/reducer'
import AccountReducer from './auth/register/reducer'
import AssignSlice from './assignuserslice/reducer'
import { configureStore } from '@reduxjs/toolkit';
import signedDocument from './signeddocument/reducer'
import viewDocument from './viewdocument/reducer'



const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  dashboard: DashboardReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  AssignUser:AssignSlice,
  signedDocument:signedDocument,
  viewDocument:viewDocument
})

type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

export default rootReducer
