/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PDFEditorSignatures } from "./pdfeditor";
import fontkit from "@pdf-lib/fontkit";
import { getAssignedUser } from "../../apiServices/addpdf/index";
import { logOutToken } from "slices/auth/login/reducer";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "pages/Utility/Utils";
import { pdfPermission } from "hooks/pdfPermission";

import { Editor } from "./editor";

const Index = () => {
  const { email } = useSelector((store: any) => store.Login.user);
  const [pdfInfo, setPdf] = useState<any>();
  const [pdf, setPdfData] = useState("");
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [pdfData, setPdfDataInfo] = useState("");
  const [stateData, setStateData] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [permission, setPermission] = useState(true);
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAssignedUser(
      accessToken,
      id,
      setPdf,
      setLoader,
      logoutFunction,
      setPdfData,
      setStateData
    ); // eslint-disable-next-line
  }, []);

  const getData = async (uint8Array: any) => {
    setPdfDataInfo(uint8Array);
    const pdfDoc = await PDFDocument.load(uint8Array, {
      ignoreEncryption: true,
    });
    pdfDoc.registerFontkit(fontkit);

    const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
    const fontBytes = await fetch(urldata).then((res) => res.arrayBuffer());

    const customFont = await pdfDoc.embedFont(fontBytes, {
      subset: true,
    });
    const pages = pdfDoc.getPages();

    for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
      if (
        (pdfInfo?.get_user_ordered[i]?.status === false ||
          pdfInfo?.get_user_ordered[i]?.status === 0) &&
        pdfInfo?.get_user_ordered[i].user_email === email
      ) {
        const x = pdfInfo.box_position[i]["x"];
        const y =
          pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
          pdfInfo.box_position[i]["y"] -
          25;

        const width = pdfInfo.box_size[i]["width"];
        const height = pdfInfo.box_size[i]["height"];

        // page_index
        pages[pdfInfo?.page_index[i] - 1]?.drawRectangle({
          x,
          y,
          width,
          height,
          // borderColor: rgb(0.2, 0.8, 0.2),
          borderColor: rgb(0.1098, 0.2745, 0.5019),
          borderWidth: 2,
          color: rgb(0.9, 0.9, 0.9),
        });
        var text;
        if (pdfInfo?.signature_type[i] === "initials") {
          text = "التأشير";
        } else {
          text = pdfInfo?.get_user_ordered[i].user_name;
        }
        const fontSize = 8;
        const textColor = rgb(0, 0, 0.9); // Blue

        // Calculate the position to center the text within the box
        const textX = x + width / 2 - (text?.length * fontSize) / 4;
        const textY = y + height / 2 - fontSize / 2;

        // Draw the text on the page
        pages[pdfInfo?.page_index[i] - 1]?.drawText(text, {
          x: textX,
          y: textY,
          color: textColor,
          font: customFont,
          size: 10,
        });
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);

    const URL: any = await blobToURL(blob);

    setPdfData(URL);
  };
  const base64ToUint8Array = (base64WithoutPrefix: any) => {
    const binaryString = atob(base64WithoutPrefix);

    const length = binaryString.length;
    const uint8Array: any = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    getData(uint8Array);
    return uint8Array;
  };

  useEffect(() => {
    if (stateData === true) {
      const base64WithoutPrefix: any = pdf.replace(
        /^data:application\/pdf;base64,/,
        ""
      );

      base64ToUint8Array(base64WithoutPrefix);
    }
    // eslint-disable-next-line
  }, [stateData]);

  const getIp = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => {
        return response.json();
      })
      .then((res: any) => {
        setIpAddress(res?.ip);
      })
      .catch((err: any) => console.error("Problem fetching my IP", err));
  };

  const permissionPayment = () => {
    // setPermission
    pdfPermission(pdfInfo, email, setPermission);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    permissionPayment();
    //eslint-disable-next-line
  }, [pdfInfo]);

  return (
    <>
      <div className="pdf-wrap">
        {pdf ? (
          <Editor
            pdf={pdf}
            pdfData={pdfData}
            setPdf={setPdfData}
            setLoader={setLoader}
            stateData={stateData}
            pdfInfo={pdfInfo}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;
