// import { deleteFile } from "apiServices/files";
import React, { useCallback, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // Form,
  // UncontrolledDropdown,
} from "reactstrap";
import { Document, Page } from "react-pdf";

import PagingControl from "Components/PagingControl";
import { getRecentFilesBase64 } from "apiServices/files";
import { useSelector } from "react-redux";

const File = (props: any) => {
  const { file } = props;
  const [modal, setModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const { accessToken } = useSelector((store: any) => store.Login);
  // const [pageDetails, setPageDetails] = useState(null);
  const [userState, setUserState] = useState("");
  const toggle = useCallback(() => {
    setPageNum(0);
    setTotalPages(0);

    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);
  const userPdfState = (id: number) => {
    getRecentFilesBase64(accessToken, setUserState, id, toggle);
  };
  return (
    <>
      <Col xl={4} sm={6}>
        {/* {isLoading === true
        ? ""
        : // <div className="loader-box">
          //   <div className="loader"></div>
          // </div>
          ""} */}
        <Card className="shadow-none border">
          <CardBody className="p-3">
            <div>
              <div
                className="avatar-xs me-3 mb-3 cursor-pointer"
                onClick={() => {
                  userPdfState(file.id);
                }}
              >
                <div className="avatar-title bg-transparent rounded">
                  <i
                    className="mdi mdi-file-document"
                    style={{ color: "blue" }}
                  ></i>
                </div>
              </div>

              {/* <Link
                className="d-flex cursor-pointer"
                to={file?.file_path_url}
                target="_blank"
              > */}
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  {file?.title?.replace(/\.pdf$/, "")}
                </h5>
              </div>
              {/* </Link> */}
            </div>
          </CardBody>
        </Card>
      </Col>

      <Modal isOpen={modal} toggle={toggle} fullscreen={true}>
        <ModalHeader toggle={toggle} tag="h4"></ModalHeader>
        <ModalBody>
          <div>
            <Document
              file={userState}
              onLoadSuccess={(data) => {
                setTotalPages(data.numPages);
              }}
              className={"pdf-canvas"}
            >
              <Page
                pageNumber={pageNum + 1}
                onLoadSuccess={(data: any) => {
                  // setPageDetails(data);
                }}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default File;
