// import { deleteFile } from "apiServices/files";
import React, { useCallback, useState } from "react";
// import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // Form,
  // UncontrolledDropdown,
} from "reactstrap";
import { Document, Page } from "react-pdf";
import PagingControl from "Components/PagingControl";

const File = (props: any) => {
  const { file } = props;
  const [modal, setModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  // const [pageDetails, setPageDetails] = useState(null);
  const [userState, setUserState] = useState("");
  const toggle = useCallback(() => {
    setPageNum(0);
    setTotalPages(0);
    if (file?.pdf_base64) {
      if (modal) {
        setModal(false);
      } else {
        setModal(true);
      }
    }
  }, [modal, file?.pdf_base64]);
  return (
    <>
      <Col xl={4} sm={6}>
        {/* {isLoading === true
        ? ""
        : // <div className="loader-box">
          //   <div className="loader"></div>
          // </div>
          ""} */}
        <Card className="shadow-none border">
          <CardBody className="p-3">
            <div>
              <div className="avatar-xs me-3 mb-3">
                <div
                  className="avatar-title bg-transparent rounded cursor-pointer"
                  onClick={() => {
                    setUserState(file?.pdf_base64);
                    toggle();
                  }}
                >
                  <i
                    className="mdi mdi-file-document"
                    style={{ color: "blue" }}
                  ></i>
                </div>
              </div>

              {/* <Link
                className="d-flex cursor-pointer"
                to={file?.file_path_url}
                target="_blank"
              > */}
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  {file?.title?.replace(/\.pdf$/, "")}
                </h5>
              </div>
              {/* </Link> */}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggle} fullscreen={true}>
        <ModalHeader toggle={toggle} tag="h4"></ModalHeader>
        <ModalBody>
          <div>
            <Document
              file={userState}
              onLoadSuccess={(data) => {
                setTotalPages(data.numPages);
              }}
              className={"pdf-canvas"}
            >
              <Page
                pageNumber={pageNum + 1}
                width={400}
                height={600}
                onLoadSuccess={(data: any) => {
                  // setPageDetails(data);
                }}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default File;
