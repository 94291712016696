// import EcommerenceProducts from "src/pages/Ecommerence/EcommerenceProducts";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

// File Manager
import FileManager from "../pages/FileManager/index";

//Ecommerce
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import Signature from "pages/Signature/index";
//Authentication pages
import Login1 from "../pages/AuthenticationInner/Login";
import Register from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import AssignUser from "../pages/AssignPdf/index";
//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesFaqs from "../pages/Utility/pages-faqs";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Editor from "pages/PDF";
import PDF from "pages/AssignPdf/index";
import ViewDocument from "pages/ViewPdf/index";
import UserPdf from "pages/UserPdf/index";
// Auth
import UserProfile from "../pages/AuthenticationInner/UserProfile";
import ResetPassword from "pages/AuthenticationInner/ResetPassword";
import ChangePassword from "pages/AuthenticationInner/ChangePassword";
import MyFiles from "../pages/MyFiles/index";
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //File Manager
  { path: "/apps-filemanager", component: <FileManager /> },

  { path: "/users-management", component: <EcommerceCustomers /> },
  { path: "/assign-pdf", component: <AssignUser /> },
  { path: "/signature", component: <Signature /> },
  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },

  { path: "/profile", component: <UserProfile /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/assign-document", component: <Editor /> },
  { path: "/pdfassign", component: <PDF /> },
  { path: "/viewDocument/:id", component: <ViewDocument /> },
  { path: "/my-files", component: <MyFiles /> },
  { path: "/view-document/:id", component: <UserPdf /> },

  // { path: "/", component: <AssignUsersData /> },
];

const publicRoutes = [
  { path: "/login", component: <Login1 /> },
  { path: "/recoverpw", component: <Recoverpw /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "change-password", component: <ChangePassword /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // // Authentication Inner
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
];
export { authProtectedRoutes, publicRoutes };
