import React from 'react'

import {
  Col,
  // Form,
  Row,
} from 'reactstrap'
import Folder from './Folder'
import File from './File'

const FileList = (props: any) => {
  const {
    files,
    folders,
    getFileFolders,
    parentFolderId,
    setParentFolderId,
    fetchRecentFiles,
    // currentPath,
  } = props

  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>
              ملفاتي
                {/* My Files */}
                </h5>
            </div>
          </Col>
          <Col>
            <div className="py-4">
              {/* {currentPath.map(dir => (
                <button
                  className="border-0 bg-transparent mx-0 pl-1"
                  onClick={e => {
                    e.preventDefault()
                    console.log('hi')
                    setParentFolderId(dir.id)
                  }}>
                  {dir.title} &gt;{' '}
                </button>
              ))} */}
            </div>
          </Col>
          {/* <Col xl={9} sm={6}>
            <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>

              <UncontrolledDropdown className="mb-0">
                <DropdownToggle
                  className="btn btn-link text-muted mt-n2"
                  tag="a">
                  <i className="mdi mdi-dots-vertical font-size-20"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#">Share Files</DropdownItem>
                  <DropdownItem href="#">Share with me</DropdownItem>
                  <DropdownItem href="#">Other Actions</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Form>
          </Col> */}
        </Row>
      </div>
      <div>
        <Row>
          {folders &&
            folders.map((folder, key: number) => {
              return (
                <Folder
                  folder={folder}
                  getFileFolders={getFileFolders}
                  key={key}
                  setParentFolderId={setParentFolderId}
                  fetchRecentFiles={fetchRecentFiles}
                />
              )
            })}

          {files &&
            files.map((file, key: number) => {
              return (
                <File
                  file={file}
                  getFileFolders={getFileFolders}
                  key={key}
                  parentFolderId={parentFolderId}
                  fetchRecentFiles={fetchRecentFiles}
                />
              )
            })}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FileList
