/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { DrawCall } from "./pdfeditor";
import { Viewer } from "./view";
import { PDFEditorSignatures, MouseEvent } from "./pdfeditor";
import { MouseEventType } from "./pdfeditor.enum";
import { getAllUsers } from "../../apiServices/assignpdf/index";
import { useDispatch, useSelector } from "react-redux";
import { logOutToken } from "slices/auth/login/reducer";
import { ToastContainer, toast } from "react-toastify";
import fontkit from "@pdf-lib/fontkit";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "pages/Utility/Utils";
// import "./style.css";

interface EditorProps {
  pdf: string;
  pdfData: any;
  setLoader: any;
  setPdf: any;
  stateData: any;
  pdfInfo: any;
}
const signatureWidth = 180;
const signatureHeight = 25;
let dataIncreament = 0;

const Editor: React.FC<EditorProps> = (props: EditorProps) => {
  const [drawCalls, setDrawCalls] = React.useState<{
    [page: number]: DrawCall[];
  }>({});
  const [allSignatureData, setAllSignatureData] = React.useState<{
    [pageNumber: number]: PDFEditorSignatures[];
  }>([]);
  const [signatureData, setSignatureData] =
    React.useState<PDFEditorSignatures | null>(null);
  const email = React.useRef<string>("");
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  /*Flags*/
  const [addSignature, setAddSignature] = React.useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [typeSet, setType] = useState({
    label: "",
    value: "",
  });

  const mouseDown = React.useRef<boolean>(false);
  const [users, setUsers] = useState([]);
  const [usersInfo, setUserInfo] = useState({
    label: "",
    value: "",
  });
  const [userData, setUserData] = useState<any>([]);

  const blockSelected = React.useRef<number>(-1);
  const dispatch = useDispatch();
  const onCanvasClicked = (mouseEvent: MouseEvent) => {
    switch (mouseEvent.type) {
      case MouseEventType.MOUSE_DOWN:
        eventHandleDown(mouseEvent);
        break;
      case MouseEventType.MOUSE_UP:
        eventHandleMouseUp(mouseEvent);
        break;
      case MouseEventType.MOUSE_MOVE:
        eventHandleMouseMove(mouseEvent);
        break;
      case MouseEventType.MOUSE_LEAVE:
        mouseDown.current = false;
        break;
      default:
        break;
    }
  };

  const eventHandleDown = (mouseEvent: MouseEvent) => {
    try {
      if (addSignature) {
        //loop through and make srue email hasnt' been used on the paage yet
        if (allSignatureData[mouseEvent.page]) {
          allSignatureData[mouseEvent.page].forEach((signature) => {
            if (signature.email === email.current) {
              // toast.error("Email already used on this page");
              // alert("Email already used on this page");
              setAddSignature(false);
              setSignatureData(null);
              throw new Error("Email already used on this page");
            }
          });
        }
        if (signatureData?.email) {
          const newPosition = {
            x: mouseEvent.pos.x - signatureWidth / 2,
            y: mouseEvent.pos.y - signatureHeight / 2,
          };

          var newSignatureData = signatureData;

          if (newSignatureData == null) {
            return false;
          }

          newSignatureData.blockDrawCall = {
            type: "rect",
            position: newPosition,
            size: {
              width: signatureWidth,
              height: signatureHeight,
            },
            data: "red",
          };
          newSignatureData.textDrawCall = {
            type: "text",
            position: {
              x: newPosition.x + 60,
              y: newPosition.y + 15,
            },
            size: {
              width: 0,
              height: 0,
            },
            data: email.current,
          };

          var newSignatures = { ...allSignatureData };
          if (!newSignatures[mouseEvent.page]) {
            newSignatures[mouseEvent.page] = [];
          }

          newSignatures[mouseEvent.page].push(newSignatureData);
          setAllSignatureData(newSignatures);
          setAddSignature(false);
        }
      } else {
        mouseDown.current = true;

        if (allSignatureData[mouseEvent.page]) {
          allSignatureData[mouseEvent.page].forEach((call, index) => {
            if (call.blockDrawCall == null || call.textDrawCall == null) {
              throw new Error("Block Draw Call or Text Draw Call is null");
            }
            if (
              mouseEvent.pos.x > call.blockDrawCall.position.x &&
              mouseEvent.pos.x <
                call.blockDrawCall.position.x + call.blockDrawCall.size.width &&
              mouseEvent.pos.y > call.blockDrawCall.position.y &&
              mouseEvent.pos.y <
                call.blockDrawCall.position.y + call.blockDrawCall.size.height
            ) {
              blockSelected.current = index;
            }
          });
        }
      }
    } catch (err) {
      console.log("error");
    }
  };

  const eventHandleMouseUp = (mouseEvent: MouseEvent) => {
    mouseDown.current = false;
  };

  const eventHandleMouseMove = (mouseEvent: MouseEvent) => {
    try {
      if (mouseDown.current && blockSelected.current !== -1) {
        const newSignatureData =
          allSignatureData[mouseEvent.page][blockSelected.current];

        if (
          newSignatureData.blockDrawCall == null ||
          newSignatureData.textDrawCall == null
        ) {
          throw new Error("Block Draw Call or Text Draw Call is null");
        }
        newSignatureData.blockDrawCall.position = {
          x: mouseEvent.pos.x - signatureWidth / 2,
          y: mouseEvent.pos.y - signatureHeight / 2,
        };
        newSignatureData.textDrawCall.position = {
          x: newSignatureData.blockDrawCall.position.x + 60,
          y: newSignatureData.blockDrawCall.position.y + 15,
        };
        setAllSignatureData({
          ...allSignatureData,
          [mouseEvent.page]: [
            ...allSignatureData[mouseEvent.page].slice(
              0,
              blockSelected.current
            ),
            newSignatureData,
            ...allSignatureData[mouseEvent.page].slice(
              blockSelected.current + 1
            ),
          ],
        });
      }
    } catch (err) {
      console.log("error");
    }
  };

  React.useEffect(() => {
    if (!addSignature) {
      setSignatureData(null);
      email.current = "";
    }
  }, [addSignature]);

  React.useEffect(() => {
    try {
      const newDrawCalls: { [page: number]: DrawCall[] } = {};
      for (let page in allSignatureData) {
        newDrawCalls[page] = [];

        allSignatureData[page].forEach((signature) => {
          if (
            signature.blockDrawCall == null ||
            signature.textDrawCall == null
          ) {
            throw new Error("Block Draw Call or Text Draw Call is null");
          }
          newDrawCalls[page].push(signature.blockDrawCall);
          newDrawCalls[page].push(signature.textDrawCall);
        });
      }
      setDrawCalls(newDrawCalls);
    } catch (error) {
      console.log("error");
    }
  }, [allSignatureData]);
  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAllUsers(accessToken, setUsers, logoutFunction, props?.setLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminSigantureData = (
    pages: any,
    email: any,
    userId: any,
    type: string
  ) => {
    // Create an object to accumulate signature data for all pages
    const newSignatureDataForAllPages: any = {};

    // if (userId) {
    // if (type) {
    if (pages?.length !== 0) {
      for (let i = 0; i < pages?.length; i++) {
        ++dataIncreament;

        const page = pages[i].value;

        if (addSignature) {
          // Check if the user's email already exists on the same page
          const existingSignaturesOnPage = allSignatureData[page] || [];
          const emailExistsOnPage = existingSignaturesOnPage.some(
            (signature) => signature.email === email
          );

          const newPosition = {
            x: 208,
            y: 431.5,
          };

          const newSignatureData: any = {
            email: email,
            order: String(dataIncreament),
            blockDrawCall: {
              type: "rect",
              position: newPosition,
              size: {
                width: 180,
                height: 25,
              },
              data: "red",
            },
            signatureType: type ? type : "",
            userId: userId,
            textDrawCall: {
              type: "text",
              position: {
                x: newPosition.x + 60,
                y: newPosition.y + 15,
              },
              size: {
                width: 0,
                height: 0,
              },
              data: email,
            },
          };

          newSignatureDataForAllPages[page] = newSignatureData;
        }
      }

      // Merge the new signature data into the existing data in the order specified by the pages array
      const mergedSignatureData: any = {};
      pages &&
        pages?.forEach((page: any) => {
          const pageValue = page.value;
          mergedSignatureData[pageValue] = [
            ...(newSignatureDataForAllPages[pageValue]
              ? [newSignatureDataForAllPages[pageValue]]
              : []),
            ...(allSignatureData[pageValue] || []),
          ];
        });

      // Update the allSignatureData state with the merged signature data
      setAllSignatureData((prevData) => ({
        ...allSignatureData,
        ...mergedSignatureData,
      }));
    } else {
      // toast.error("Please Select the Page");
      toast.error("الرجاء اختيار الصفحة");
    }
    // } else {
    //   toast.error("الرجاء تحديد النوع");
    // }
  };

  useEffect(() => {
    dataIncreament = 0;
  }, []);

  return (
    <>
      <div className="container">
        <ToastContainer />

        <Viewer
          pdf={props.pdf}
          drawCalls={drawCalls}
          eventHandler={onCanvasClicked}
          setAddSignature={setAddSignature}
          addSignature={addSignature}
          signatureData={signatureData}
          users={users}
          setUserInfo={setUserInfo}
          email={email}
          usersInfo={usersInfo}
          setUserData={setUserData}
          setSignatureData={setSignatureData}
          setSelectedPages={setSelectedPages}
          adminSigantureData={adminSigantureData}
          selectedPages={selectedPages}
          typeSet={typeSet}
          setType={setType}
          stateData={props.stateData}
          setPdf={props.setPdf}
          allSignatureData={allSignatureData}
          setAllSignatureData={setAllSignatureData}
          pdfInfo={props?.pdfInfo}
        />
      </div>
    </>
  );
};

export { Editor };
