import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
// import { CreateFile } from 'apiServices/files'
import { useSelector } from 'react-redux'
import { createFile } from 'apiServices/files'
const CreateFile = (props: any) => {
  const {
    openModal,
    setOpenModal,
    parent_id,
    getFileFolders,
    fetchRecentFiles,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoading] = useState(false)
  const [file, setFile] = useState(null)

  const { accessToken } = useSelector((store: any) => store.Login)
  // validation
  // const validation: any = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     pdf: null,
  //   },

  //   validationSchema: Yup.object({
  //     pdf: Yup.string().required('Please enter file name'),
  //   }),
  //   onSubmit: async (values: any) => {
  //     // const { pdf } = values
  //     const pdf = file
  //     console.log('parent_id', parent_id)

  //     createFile(
  //       { pdf, parent_id },
  //       accessToken,
  //       setLoading,
  //       setOpenModal,
  //       getFileFolders
  //     )

  //     validation.resetForm()
  //   },
  // })
  const toggle = () => setOpenModal(!openModal)

  const onSubmit = async (e: any) => {
    e.preventDefault()



    createFile(
      { pdf: file, parent_id },
      accessToken,
      setLoading,
      setOpenModal,
      getFileFolders,
      fetchRecentFiles
    )

    // validation.resetForm()
  }

  const handleFileChange = e => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      setFile(selectedFile)
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {/* Create File */}
          إنشاء ملف
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col xs={12}>
                <div className="mb-3">
                  <Label>
                    {/* Folder name */}
                    اسم المجلد
                    </Label>
                    
                  <Input
                    name="pdf"
                    type="file"
                    accept="application/pdf"
                    onChange={e => {
                      handleFileChange(e)
                    }}
                    // onBlur={validation.handleBlur}
                    // value={validation.values.pdf || ''}
                    // placeholder="new"
                    // invalid={
                    //   validation.touched.pdf && validation.errors.pdf
                    //     ? true
                    //     : false
                    // }
                  />
                  {/* {validation.touched.pdf && validation.errors.pdf ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pdf}
                    </FormFeedback>
                  ) : null} */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="success"
                    className="save-customer">
                    {/* Add */}
                    إضافة
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateFile
