import { getRecentFilesBase64Convert } from "apiServices/files";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
const FileItem = (props: any) => {
  const { file, setPdf, setOpenModal, setPdfData, setFolderId } = props;
  const { accessToken } = useSelector((store: any) => store.Login);
  function base64ToBlob(base64String) {
    // Decode the base64 string
    base64String = base64String.replace("data:application/pdf;base64,", "");
    const byteCharacters = atob(base64String);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create Blob object
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const fileObject = new File([blob], file.title, {
      type: "application/pdf",
    });
    return fileObject;
  }

  const userPdfState = (id: number) => {
    getRecentFilesBase64Convert(
      accessToken,
      setPdf,
      id,
      base64ToBlob,
      setPdfData,
      setOpenModal
    );
  };

  return (
    <Col xl={4} sm={6}>
      <Card className="shadow-none border">
        <CardBody className="p-3">
          <div>
            <div className="avatar-xs me-3 mb-3">
              <div className="avatar-title bg-transparent rounded">
                <i
                  className="mdi mdi-file-document"
                  style={{ color: "blue" }}
                ></i>
              </div>
            </div>

            <Link
              className="d-flex cursor-pointer"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                userPdfState(file.id);

                setFolderId(file?.folder_id);
              }}
            >
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  {file.title?.replace(/\.pdf$/, "")}
                </h5>
              </div>
            </Link>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FileItem;
