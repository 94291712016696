import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  // Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import CreateFolder from './CreateFolderModal'
import CreateFile from './CreateFileModal'
import FileLeftBarChild from './FileLeftBarChild'

const FileLeftBar = (props: any) => {
  const {
    getFileFolders,
    parentFolderId,
    setParentFolderId,
    fetchRecentFiles,
    hierarchyFolders,
  } = props
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openFileModal, setOpenFileModal] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <div className="mb-3">
                <UncontrolledDropdown>
                  <DropdownToggle className="btn btn-light w-100" type="button">
                    <i className="mdi mdi-plus me-1"></i>
                     {/* Create New */}
                     إنشاء جديد
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={e => {
                        e.preventDefault()
                        setOpenModal(true)
                      }}>
                      <i className="bx bx-folder me-1"></i> 
                      {/* Folder */}
                      مجلد

                    </DropdownItem>
                    {parentFolderId && (
                      <DropdownItem
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setOpenFileModal(true)
                        }}>
                        <i className="bx bx-file me-1"></i> 
                        {/* File */}
                        ملف

                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {hierarchyFolders &&
                hierarchyFolders.map((folder: any, key: number) => (
                  <FileLeftBarChild
                    key={key}
                    folder={folder}
                    setParentFolderId={setParentFolderId}
                  />
                ))}
            </div>
          </div>
        </CardBody>
      </Card>
      <CreateFolder
        parent_id={parentFolderId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        getFileFolders={getFileFolders}
      />

      <CreateFile
        parent_id={parentFolderId}
        openModal={openFileModal}
        setOpenModal={setOpenFileModal}
        getFileFolders={getFileFolders}
        fetchRecentFiles={fetchRecentFiles}
      />
    </React.Fragment>
  )
}

export default FileLeftBar
