import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

//i18n
import { withTranslation } from 'react-i18next'

import withRouter from '../../Common/withRouter'

import { useDispatch, useSelector } from 'react-redux'
import { logOutToken } from 'slices/auth/login/reducer'

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setUsername] = useState('')

  const { user } = useSelector((state: any) => state.Login)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    setUsername(user?.name)
  }, [user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block ">
        <DropdownToggle
          className="btn header-item drop--menu"
          id="page-header-user-dropdown"
          tag="button">
          <img
            className="rounded-circle header-profile-user"
            src="/no_avatar.jpeg"
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {username || 'admin'}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end drop--menu">
          <DropdownItem tag="a" href={process.env.PUBLIC_URL + '/profile'}>
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t('الملف الشخصي')}{' '}
            {/* Profile */}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t('Settings')}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <button
            className="dropdown-item"
            onClick={() => dispatch(logOutToken({}))}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('تسجيل الخروج')}</span>
            {/* Logout  */}
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ProfileMenu))
