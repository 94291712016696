import { deleteFile, getRecentFilesBase64 } from "apiServices/files";
import PagingControl from "Components/PagingControl";
import React, { useCallback, useState } from "react";
import { Document, Page } from "react-pdf";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

const RecentFile = (props: any) => {
  const { recentFiles, getFileFolders, fetchRecentFiles } = props;

  const { accessToken } = useSelector((store: any) => store.Login);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);

  const [userState, setUserState] = useState("");

  const userPdfState = (id: number) => {
    getRecentFilesBase64(accessToken, setUserState, id, toggle);
  };

  const toggle = useCallback(() => {
    setPageNum(0);
    setTotalPages(0);
    // if (userState) {
    if (modal) {
      setModal(false);
      // setCustomer(null);
    } else {
      setModal(true);
    }
    // }
  }, [modal]);
  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="d-flex flex-wrap">
          <h5 className="font-size-16 me-3">
            {/* Recent Files */}
            الملفات الأخيرة
          </h5>

          {/* <div className="ms-auto">
            <Link to="#" className="fw-medium text-reset">
              View All
            </Link>
          </div> */}
        </div>
        <hr className="mt-2" />

        <div className="table-responsive">
          {isLoading ? (
            ""
          ) : (
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">
                    {/* Name */}
                    الاسم
                  </th>
                  <th scope="col">
                    {/* Date modified */}
                    تاريخ التعديل
                  </th>
                  <th scope="col" colSpan={2}>
                    {/* Size */}
                    الحجم
                  </th>
                </tr>
              </thead>
              <tbody>
                {recentFiles?.map((item: any, key: number) => (
                  <tr key={key}>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        userPdfState(item.id);
                      }}
                    >
                      {/* <Link
                        to={item?.file_path_url}
                        target="_blank"
                        className="text-dark fw-medium"
                      > */}
                      <i
                        className="mdi mdi-file-document"
                        style={{ color: "blue" }}
                      ></i>{" "}
                      {item?.title?.replace(/\.pdf$/, "")}
                      {/* </Link> */}
                    </td>
                    <td>{new Date(item.updated_at).toLocaleDateString()}</td>
                    <td>{item.file_size} </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="font-size-16 text-muted"
                          role="button"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          {/* <DropdownItem href="#">Open</DropdownItem> */}
                          <DropdownItem
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFile(
                                item.id,
                                accessToken,
                                getFileFolders,
                                setLoading,
                                fetchRecentFiles
                              );
                            }}
                          >
                            {/* Remove */}
                            حذف
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} fullscreen={true}>
        <ModalHeader toggle={toggle} tag="h4"></ModalHeader>
        <ModalBody>
          <div>
            <Document
              file={userState}
              onLoadSuccess={(data) => {
                setTotalPages(data.numPages);
              }}
              className={"pdf-canvas"}
            >
              <Page
                pageNumber={pageNum + 1}
                onLoadSuccess={(data: any) => {
                  // setPageDetails(data);
                }}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RecentFile;
