/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from "react";
import { Dialog } from "./Dialog";
import SignatureCanvas from "react-signature-canvas";
import { ConfirmOrCancel } from "./ConfirmorCancel";
import { primary45 } from "../../src/pages/Utility/colors";
import { ToastContainer } from "react-toastify";

interface AddSigDialogProps {
  onConfirm: (sigURL: string) => void;
  onClose: () => void;
  autoDate: boolean;
  setAutoDate: (autoDate: boolean) => void;
  setSignature: any;
  accessToken: any;
  logoutFunction: any;
  setLoader: any;
  sigRef: any;
  imageBase64: any;
  setImage: any;
  test: any;
  setTest: any;
  signatureType: any;
  setInitialText: Function;
}

export function AddSigDialog({
  onConfirm,
  onClose,
  autoDate,
  setAutoDate,
  setSignature,
  accessToken,
  logoutFunction,
  setLoader,
  sigRef,
  imageBase64,
  setImage,
  test,
  setTest,
  signatureType,
  setInitialText,
}: AddSigDialogProps) {
  const styles: Record<string, React.CSSProperties> = {
    sigContainer: {
      display: "flex",
      justifyContent: "center",
    },
    sigBlock: {
      display: "inline-block",
      border: `1px solid ${primary45}`,
    },
    instructions: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      color: primary45,
      marginTop: 8,
      width: 600,
      alignSelf: "center",
    },
    instructionsContainer: {
      display: "flex",
      justifyContent: "center",
    },
    confirmContainer: {
      paddingTop: "10px",
    },
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        isVisible={true}
        // title={"Add signature"}
        title={
          signatureType?.includes("") && signatureType?.includes("initials")
            ? "إضافة التأشير والتوقيع"
            : signatureType?.includes("")
            ? "إضافة التوقيع"
            : signatureType?.includes("initials")
            ? "إضافة التأشير"
            : ""
        }
        onClose={onClose}
        body={
          <div className="mon" style={styles.container}>
            <div style={styles.sigContainer}>
              <div className="line" style={styles.sigBlock}>
                {imageBase64 && test === true && signatureType?.includes("") ? (
                  <div
                    style={{
                      width: "550px",
                      height: "160px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={imageBase64} alt="" />
                  </div>
                ) : (
                  signatureType?.includes("") && (
                    <SignatureCanvas
                      penColor={"black"}
                      velocityFilterWeight={0.1}
                      ref={sigRef}
                      canvasProps={{
                        width: "550",
                        height: 160,
                        className: "pointer",
                      }}
                      from
                    />
                  )
                )}
              </div>
            </div>
            {imageBase64 && test === true
              ? ""
              : signatureType?.includes("") && (
                  <div style={styles.instructionsContainer}>
                    <div style={styles.instructions}>
                      <div>
                        {/* Draw your signature above */}
                        الرجاء رسم التوقيع في المكان المخصص
                      </div>
                    </div>
                  </div>
                )}
            {signatureType?.includes("initials") && (
              <>
                <div
                  style={{
                    marginRight: "13px",
                    color: "black",
                    marginTop: "5px",
                  }}
                >
                  {/* Draw your signature above */}
                  الرجاء إضافة التأشير
                </div>

                <input
                  type="text"
                  style={{ marginRight: "13px", marginTop: "2px", width: 550 }}
                  onChange={(e) => {
                    setInitialText(e.target.value);
                  }}
                />
              </>
            )}
            <div style={styles.confirmContainer}>
              <ConfirmOrCancel
                onCancel={onClose}
                onConfirm={() => {
                  if (test === false) {
                    if (sigRef.current) {
                      const sigURL = sigRef.current.toDataURL();
                      onConfirm(sigURL);
                    } else {
                      onConfirm("");
                    }
                  } else {
                    onConfirm(imageBase64);
                  }
                }}
              />
            </div>
          </div>
        }
        setSignature={setSignature}
        accessToken={accessToken}
        logoutFunction={logoutFunction}
        setLoader={setLoader}
        sigRef={sigRef}
        setImage={setImage}
        setTest={setTest}
        signatureType={signatureType}
      />
    </>
  );
}
