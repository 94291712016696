import axios from "axios";
import { toast } from "react-toastify";

export const getAllSignature = async (
  token: any,
  setSignature: any,
  dataFunction: any,

  setLoader: any
) => {
  try {
    setLoader(true);

    const allSignature = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-pad-signature`,
      {
        headers: {
          Authorization: token,
        },
      }
    );


    setSignature(allSignature?.data?.data);

    setLoader(false);

    
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const deleteSignature = async (
  token: any,
  id: any,
  setSignature: any,
  dataFunction: any,
  setUserId: any,

  setLoader: any
) => {
  try {
    setLoader(true);
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/delete-signature/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    toast.success("Signature Deleted Successfully");
    setUserId(null);
    getAllSignature(token, setSignature, dataFunction, setLoader);
  } catch (err: any) {
    setLoader(false);
    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const createSignature = async (
  userData: any,
  token: any,
  setSignature: any,
  dataFunction: any,
  toggle: any,
  state: any,
  setLoader: any,
  setImageSign: any
) => {
  try {
    setLoader(true);

    const newUser = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/create-pad-signature`,
      {
        signature_name: userData.name,
        image: state,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    toast.success(newUser.data.message);
    toggle();
    setImageSign("");
    getAllSignature(token, setSignature, dataFunction, setLoader);
   
  } catch (error: any) {


    setLoader(false);
    if (error?.response?.data?.status === 401) {
      dataFunction();
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};
