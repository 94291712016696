import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Col,
  Form,
  // FormFeedback,
  // Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createFolderAccess } from "apiServices/files";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getAllUsers } from "../../apiServices/assignpdf/index";
import { logOutToken } from "slices/auth/login/reducer";

const MultiSelectcustomStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "red" : "#fff",
    backgroundColor: state.isSelected ? "#006cf9" : "#0c2144",
    "&:hover": {
      backgroundColor: "#006cf9",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0c2144",
    borderColor: "rgba(60 ,86 ,124, 50%)",
    "&:hover": {
      borderColor: "rgba(60 ,86 ,124, 50%)",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    // color: state.isFocused
    //   ? "rgba(209, 228, 255, 0.5)"
    //   : "rgba(209, 228, 255, 0.5)",
  }),
};

const CreateUserAccessModal = (props: any) => {
  const dispatch = useDispatch();
  const { openUserModal, setOpenUserModal, parent_id, getFileFolders } = props;
  const [isLoading, setLoading] = useState(false);
  const { accessToken } = useSelector((store: any) => store.Login);
  const [users, setUsers] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  // const [isEdit, setIsEdit] = useState<boolean>(false);

  // validation
  const toggle = () => {
    setOpenUserModal(!openUserModal);
  };

  const onSubmit = async (values: any) => {
    const { userList } = values;
    const userIds = userList.map((user: any) => user.value).join(",");
    createFolderAccess(
      { user_id: userIds, folder_id: parent_id },
      accessToken,
      setLoading,
      setOpenUserModal,
      getFileFolders
    );
  };
  // eslint-disable-next-line
  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };

  useEffect(() => {
    getAllUsers(accessToken, setUsers, logoutFunction, setLoading);
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      userList: [],
    },
    validationSchema: Yup.object({
      userList: Yup.array().required("Please select users"),
    }),
    onSubmit,
  });

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      ) : (
        <Modal toggle={toggle} isOpen={openUserModal}>
          <ModalHeader toggle={toggle} tag="h4">
            {/* Add Folder Access */}
            إضافة الوصول إلى المجلد
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>
                      {/* Users */}
                      المستخدمين
                    </Label>
                    <Select
                      styles={MultiSelectcustomStyles}
                      options={users}
                     
                      value={userList}
                      onChange={(e) => {
                        let dataId = e.map((data: any) => {
                          return {
                            value: data.value,
                            label: data.label,
                          };
                        });
                        setUserList(dataId);
                        formik.setFieldValue("userList", dataId);
                      }}
                      isDisabled={false}
                      isMulti
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <Button
                      type="submit"
                      color="success"
                      className="save-customer"
                    >
                      إضافة
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CreateUserAccessModal;
