import React, { CSSProperties } from "react";
import { primary45 } from "../../src/pages/Utility/colors";
// import { FaTimes } from 'react-icons/fa';
import { Modal } from "./Modal";

import { getAllSignaturePdf } from "apiServices/addpdf";

interface DialogProps {
  isVisible: boolean;
  body: React.ReactNode;
  onClose: () => void;
  title?: string;
  noPadding?: boolean;
  backgroundColor?: string;
  positionTop?: string;
  style?: CSSProperties;
  setSignature: any;
  accessToken: any;
  logoutFunction: any;
  setLoader: any;
  sigRef: any;
  setImage: any;
  setTest: any;
  signatureType: any;
}

export function Dialog({
  isVisible,
  body,
  onClose,
  title,
  noPadding,
  backgroundColor,
  positionTop,
  style,
  setSignature,
  accessToken,
  logoutFunction,
  setLoader,
  sigRef,
  setImage,
  setTest,
  signatureType,
}: // existingSignatureFunction
DialogProps) {
  if (!isVisible) {
    return null;
  }

  const styles: Record<string, CSSProperties> = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: positionTop || "50%",
      left: "50%",
      transform: "translate(35%, -50%)",
      zIndex: 999,
      ...style,
    },
    header: {
      backgroundColor: primary45,
      color: "#FFF",
      padding: 8,
      fontSize: 14,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    body: {
      padding: noPadding ? 0 : 14,
      backgroundColor: backgroundColor ? backgroundColor : "#FFF",
    },
    xIcon: {
      cursor: "pointer",
    },
  };

  const existingSignatureFunction = () => {
    getAllSignaturePdf(
      accessToken,
      setSignature,
      logoutFunction,
      setLoader,
      sigRef,
      setImage,
      setTest
    );
  };

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      positionTop={positionTop}
      style={style}
    >
      <div className="" style={styles.container}>
        <div className="demo" style={styles.header}>
          <div>{title}</div>
          {signatureType.includes("") && (
            <div>
              <button
                type="button"
                style={{ borderRadius: "4px", border: "none" }}
                onClick={existingSignatureFunction}
              >
                {/* Add Existing Signature */}
                إضافة التوقيع الحالي
              </button>
            </div>
          )}
        </div>
        <div style={styles.body}>{body}</div>
      </div>
    </Modal>
  );
}
