import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Collapse } from 'reactstrap'

const FileLeftBarChild = (props: any) => {
  const { folder, setParentFolderId, key } = props
  const [isOpen, setIsOpen] = useState<boolean>(key === 0 ? true : false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <ul className="list-unstyled categories-list">
        <li>
          <div className="custom-accordion">
            <div className="d-flex align-items-center">
              <Link
                className="text-body fw-medium py-1 d-flex align-items-center"
                onClick={e => {
                  e.preventDefault()
                  setParentFolderId(folder.id)
                }}
                to="#">
                <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{' '}
                {folder.name}
              </Link>
              <i
                onClick={e => {
                  e.preventDefault()
                  toggle()
                }}
                className={
                  isOpen
                    ? 'mdi mdi-chevron-up accor-down-icon ms-auto cursor-pointer'
                    : 'mdi mdi-chevron-down accor-down-icon ms-auto cursor-pointer'
                }
                style={{ marginLeft: 'auto' }} // Added to align the icon to the right
              />
            </div>

            <Collapse isOpen={isOpen}>
              <div className="card border-0 shadow-none ps-2 m-0">
                <ul className="list-unstyled m-0">
                  <>
                    {folder.folder_children.map((obj: any) => {
                      return (
                        <>
                          {obj.folder_children.length ? (
                            <FileLeftBarChild
                              folder={obj}
                              setParentFolderId={setParentFolderId}
                            />
                          ) : (
                            <li>
                              <Link
                                to="#"
                                onClick={e => {
                                  e.preventDefault()
                                  setParentFolderId(obj.id)
                                }}
                                className="d-flex align-items-center">
                                <span className="me-auto">{obj.name}</span>
                              </Link>
                            </li>
                          )}
                        </>
                      )
                    })}
                  </>
                </ul>
              </div>
            </Collapse>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default FileLeftBarChild
