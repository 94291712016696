/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { assignedUserSigned } from "slices/auth/login/reducer";

const Dashboard = () => {
  document.title = "Dashboards | ESignature";
  const navigate = useNavigate();

  const [totalUsers, setTotalUsers] = useState(0);
  const dispatch = useDispatch();
  const [totalUnsignedDocs, setTotalUnsignedDocs] = useState(0);
  const [totalSignedDocs, setTotalSignedDocs] = useState(0);
  const { user, accessToken } = useSelector((store: any) => store.Login);

  const getDashboardDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/dashboard-details`,
        {
          headers: {
            Authorization: accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      setTotalUsers(response.data.user_count);
      setTotalSignedDocs(response.data.total_signed_docs);
      setTotalUnsignedDocs(response.data.total_unsigned_docs);
      dispatch(
        assignedUserSigned(
          response.data.total_unsigned_docs
            ? response.data.total_unsigned_docs
            : 0
        )
      );
    } catch (error) {
      console.log("Error while fetching dashboard details : ", error);
      toast.success("حدث خطأ ما");
    }
  };

  const navigateAssignPdf = () => {
    if (accessToken) {
      navigate(`/assign-pdf`);
    }
  };
  useEffect(() => {
    if (accessToken) {
      getDashboardDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            // title="Dashboards"
            // breadcrumbItem="Dashboard"
            title="لوحة المعلومات"
            breadcrumbItem="الصفحة الرئيسية"
          />

          <Row>
            {user.isAdmin ? (
              <Col>
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">
                      {/* Total User */}
                      العدد الكلي للمستخدمين
                    </h2>
                    <p className="card-text">{totalUsers}</p>
                  </div>
                </div>
              </Col>
            ) : (
              <Col>
                <button
                  className="btn btn-success cursor-pointer"
                  style={{ fontSize: "15px", fontWeight: "600" }}
                  onClick={() => {
                    navigateAssignPdf();
                  }}
                >
                  {/* <button
              className="card-body card-height "
              style={{ fontSize: "15px", fontWeight: "600" }}
            > */}
                  <p className="card-text">قائمة الملفات الموقعة</p>
                  {/* </button> */}
                </button>
              </Col>
            )}
            <Col>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    {/* Total Signed Docs */}
                    الملفات المنجزة
                  </h5>
                  <p className="card-text">{totalSignedDocs}</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    {/* Total Unsigned Docs */}
                    الملفات غير المنجزة
                  </h5>
                  <p className="card-text">{totalUnsignedDocs}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
