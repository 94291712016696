import FileAccess from "pages/ViewFileAccess";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  BreadcrumbItem,
  // Card,
  // CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
interface props {
  show: boolean;
  onClick: any;
  userId: any;
  onCloseClick: any;
}

const RemoveAccessModal = ({ show, onClick, userId, onCloseClick }: props) => {
  const [parentFolderId, setParentFolderId] = useState<any>("");
  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={onCloseClick} className="file-modal">
        <ModalBody>
          <div className="page-content">
            {/* {isLoading ? (
            <div className="loader-box">
              <div className="loader"></div>
            </div>
          ) : ( */}
            <Container fluid>
              <Row>
                <Col className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4
                      className="mb-sm-0 font-size-18 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setParentFolderId(null);
                      }}
                    >
                      {/* File Manager */}
                      مدير الملفات
                    </h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <BreadcrumbItem>
                          <Link
                            to="#"
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setParentFolderId(null);
                            }}
                          >
                            {/* File Manager */}
                            مدير الملفات
                          </Link>
                        </BreadcrumbItem>
                        {/* {currentPath.map((path) => {
                          return ( */}
                        <BreadcrumbItem>
                          <Link
                            to="#"
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              //   setParentFolderId(path.id);
                            }}
                          >
                            {/* {path.title} */}
                          </Link>
                        </BreadcrumbItem>
                        {/* );
                        })} */}
                      </ol>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="d-xl-flex">
                <div className="w-100">
                  <FileAccess
                    userId={userId}
                    parentFolderId={parentFolderId}
                    setParentFolderId={setParentFolderId}
                    onCloseClick={onCloseClick}
                  />
                </div>
              </div>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RemoveAccessModal;
