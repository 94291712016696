import React, { useEffect, useRef, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist";
import { DrawCall, MouseEvent } from "./pdfeditor";
import { MouseEventType } from "./pdfeditor.enum";
export interface RendererProps {
  pdf: PDFDocumentProxy | undefined;
  drawCalls: { [page: number]: DrawCall[] };
  zoom: number;
  page: number;
  eventHandler?: (mouseEvent: MouseEvent) => void;
}

const Renderer: React.FC<RendererProps> = ({
  pdf,
  zoom,
  page,
  drawCalls,
  eventHandler,
}: RendererProps) => {
  const drawing = useRef<boolean>(false);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const [canvasDimensions, setCanvasDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const render = async () => {
    try {
      if (!drawing.current && pdf && canvas.current) {
        drawing.current = true;
        const ctx = canvas.current.getContext("2d");
        if (!ctx) throw new Error("CTX could not be loaded");

        ctx.clearRect(200, 0, ctx.canvas.width, ctx.canvas.height);

        const pageObject = await pdf.getPage(page);
        const viewport = pageObject.getViewport({ scale: zoom });

        setCanvasDimensions({
          width: viewport.width,
          height: viewport.height,
        });

        await pageObject.render({
          canvasContext: ctx,
          viewport: pageObject.getViewport({ scale: zoom }),
        }).promise;

        drawCalls[page]?.forEach((call) => {
          switch (call.type) {
            case "text":
              ctx.font = `${12 * zoom}px Arial`; // Ensure font size is set
              ctx.fillStyle = "blue";
              const maxWidth = 300 * zoom; // Set your desired maximum width

              // Split text into lines based on maxWidth
              const words = call.data.split("");
              let line = "";
              const lineHeight = 12 * zoom * 1.2; // Adjust line height as needed
              let currentY = call.position.y * zoom;

              words.forEach((char, index) => {
                const testLine = line + char;
                const testWidth = ctx.measureText(testLine).width;

                if (testWidth > maxWidth && line.length > 0) {
                  // Draw the current line and move to the next line
                  ctx.fillText(line, call.position.x * zoom, currentY);
                  line = char; // Start new line with the current character
                  currentY += lineHeight; // Move to the next line
                } else {
                  line = testLine;
                }
              });

              // Draw the last line
              if (line.length > 0) {
                ctx.fillText(line, call.position.x * zoom, currentY);
              }
              break;
            case "image":
              ctx.drawImage(
                call.data,
                call.position.x,
                call.position.y,
                call.size.width,
                call.size.height
              );
              break;
          }
        });

        drawing.current = false;
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    render();
    // eslint-disable-next-line
  }, [canvas.current, drawCalls, zoom, page, pdf]);

  const emitEvent = (
    e: React.MouseEvent<HTMLCanvasElement, globalThis.MouseEvent>,
    mouseEventType: MouseEventType
  ) => {
    if (eventHandler) {
      const newMouseEvent: MouseEvent = {
        pos: {
          x: e.nativeEvent.offsetX / zoom,
          y: e.nativeEvent.offsetY / zoom,
        },
        type: mouseEventType,
        page: page,
      };
      eventHandler(newMouseEvent);
    }
  };

  return (
    <div className="canvas-container">
      <canvas
        ref={canvas}
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        onMouseDownCapture={(e) => emitEvent(e, MouseEventType.MOUSE_DOWN)}
        onMouseUpCapture={(e) => emitEvent(e, MouseEventType.MOUSE_UP)}
        onMouseMoveCapture={(e) => emitEvent(e, MouseEventType.MOUSE_MOVE)}
        onMouseOutCapture={(e) => emitEvent(e, MouseEventType.MOUSE_LEAVE)}
      />
    </div>
  );
};

export { Renderer };
