import axios from "axios";
import { toast } from "react-toastify";

export const getAllUsers = async (
  token: any,
  setCustomer: any,
  dataFunction: any,

  setLoader: any
) => {
  try {
    setLoader(true);

    const allUsers = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-users`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setCustomer(allUsers?.data?.data);

    setLoader(false);

   
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const deleteUser = async (
  token: any,
  id: any,
  setCustomer: any,
  dataFunction: any,
  setUserId: any,

  setLoader: any
) => {
  try {
    setLoader(true);
    const deleteData = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/delete-users/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    toast.success(deleteData?.data?.message);
    setUserId(null);
    getAllUsers(token, setCustomer, dataFunction, setLoader);
    setLoader(false);
  } catch (err: any) {
    setLoader(false);
    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const updateUser = async (
  token: any,
  singleCustomer: any,
  dataFunction: any,
  toggle: any,
  setCustomer: any,

  setLoader: any
) => {
  try {
    setLoader(true);
    let userCreated = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/update-users`,
      {
        ...singleCustomer,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    toast.success(userCreated.data.message);

    toggle();
    getAllUsers(token, setCustomer, dataFunction, setLoader);
  } catch (err: any) {
    setLoader(false);
    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const createUser = async (
  userData: any,
  token: any,
  setCustomer: any,
  dataFunction: any,
  toggle: any,

  setLoader: any
) => {
  try {
    setLoader(true);
    const newUser = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/users/created`,
      {
        name: userData.name,
        email: userData.email,
        role: userData.role,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    toast.success(newUser.data.message);
    toggle();
    getAllUsers(token, setCustomer, dataFunction, setLoader);
   
  } catch (error: any) {
    

    setLoader(false);
    if (error?.response?.data?.status === 401) {
      dataFunction();
    } else if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error?.email[0]);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};
