/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useRef, useCallback, useState } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";
import { assignedUserSigned, logOutToken } from "slices/auth/login/reducer";
// MetisMenu
import MetisMenu from "metismenujs";
import axios from "axios";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useDispatch, useSelector } from "react-redux";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((store: any) => store.Login.user);
  const { accessToken } = useSelector((store: any) => store.Login);
  const userSigned = useSelector((store: any) => store?.Login?.userSigned);
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const getDashboardDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/dashboard-details`,
        {
          headers: {
            Authorization: accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(
        assignedUserSigned(
          response.data.total_unsigned_docs
            ? response.data.total_unsigned_docs
            : 0
        )
      );
    } catch (error) {}
  };
  useEffect(() => {
    if (accessToken) {
      getDashboardDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.router.location.pathname]);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="py-4">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
              {props.t("القائمة الرئيسية")}
              {/* {props.t('Menu')} */}
            </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>
                  {/* {props.t('Dashboard')} */}
                  الصفحة الرئيسية
                </span>
              </Link>
            </li>
 	{isAdmin === 1 || isAdmin === true ? (
              <li className="assign-pdf-box">
                <Link to="/assign-pdf">
                  <i className="bx bx-user"></i>
                  {userSigned && (
                    <div className="notification-dot ">
                      <div className="notification-number">{userSigned}</div>
                    </div>
                  )}
                  <span>
                    {/* {props.t('Assign PDF')} */}
                    تعيين مستخدمين للتوقيع
                  </span>
                </Link>
              </li>
            ) : (
              <li className="assign-pdf-box">
                <Link to="/assign-pdf">
                  <i className="bx bx-user"></i>
                  {userSigned && (
                    <div className="notification-dot ">
                      <div className="notification-number">{userSigned}</div>
                    </div>
                  )}
                  <span>
                    {/* {props.t('Assign PDF')} */}
                    ملفات التوقيع
                  </span>
                </Link>
              </li>
            )}
            {isAdmin === 1 || isAdmin === true ? (
              <>
                <li>
                  <Link to="/apps-filemanager">
                    <i className="bx bx-file"></i>
                    {/* <span>{props.t('File Manager')}</span> */}
                    <span> إدارة الملفات</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/my-files">
                    <i className="bx bx-file"></i>
                    {/* <span>{props.t('My Files')}</span> */}
                    <span> ملفاتي</span>
                  </Link>
                </li>
              </>
            )}
            {(isAdmin === 1 || isAdmin === true) && (
              <>
                <li>
                  <Link to="/users-management">
                    <i className="mdi mdi-account-group"></i>
                    <span>
                      {/* {props.t('User Management')} */}
                      إدارة المستخدمين
                    </span>
                  </Link>
                </li>
              </>
            )}
            {(isAdmin === 0 || isAdmin === false) && (
              <li>
                <Link to="/signature">
                  <i className="fas fa-file-signature"></i>
                  {/* <span>{props.t('My Signature')}</span> */}
                  <span>{props.t("توقيعي")}</span>
                </Link>
              </li>
            )}
           
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
