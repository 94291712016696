export const pdfPermission = (
  pdfInfo: any,
  email: String,
  setPermission: any
) => {
  if (pdfInfo) {
    if (pdfInfo.enable_hierarchy === 1 || pdfInfo.enable_hierarchy === true) {
      const orderNumber =
        Number(
          pdfInfo.get_user_ordered.find(
            (order: any) => order.user_email === email
          ).order_num
        );
      for (let i = 0; i < pdfInfo.get_user_ordered.length; i++) {
        if (pdfInfo.get_user_ordered[i].order_num < orderNumber) {
          if (
            pdfInfo.get_user_ordered[i]?.status === 1 ||
            pdfInfo.get_user_ordered[i]?.status === true
          ) {
            setPermission(true);
          } else {
            setPermission(false);
            return false;
          }
        } else {
          setPermission(true);
        }
      }
    } else {
      setPermission(true);
    }
  }
};
