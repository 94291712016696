import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'
const Folder = (props: any) => {
  const { folder, setParentFolderId } = props

  return (
    <Col xl={4} sm={6}>
      <Card className="shadow-none border">
        <CardBody className="p-3">
          <div>
            <div
              className="avatar-xs me-3 mb-3"
              onClick={async e => {
                e.preventDefault()
                setParentFolderId(folder.id)
              }}>
              <div className="avatar-title bg-transparent rounded">
                <i className="bx bxs-folder font-size-24 text-warning cursor-pointer"></i>
              </div>
            </div>
            <div className="d-flex">
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  <Link
                    to="#"
                    onClick={async e => {
                      e.preventDefault()
                      setParentFolderId(folder.id)
                    }}
                    className="text-body cursor-pointer">
                    {folder.name}
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default Folder
