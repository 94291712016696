import { deleteFolder, renameFolder } from "apiServices/files";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // Form,
  UncontrolledDropdown,
} from "reactstrap";
import CreateFolder from "./CreateFolderModal";
import CreateUserAccessModal from "./CreateUserAccessModal";
const Folder = (props: any) => {
  const { folder, getFileFolders, setParentFolderId, fetchRecentFiles } = props;
  const { accessToken } = useSelector((store: any) => store.Login);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [canRename, setCanRename] = useState<boolean>(false);
  const [updatedFolderName, setUpdatedFolderName] = useState(folder.name);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);

  const onRename = (e) => {
    e.preventDefault();
    const body = {
      parent_id: folder.id,
      folderName: updatedFolderName,
    };
    renameFolder(body, accessToken, getFileFolders, setLoading, setCanRename);
  };

  return (
    <Col xl={4} sm={6}>
      {isLoading === true
        ? ""
        : // <div className="loader-box">
          //   <div className="loader"></div>
          // </div>
          ""}
      <Card className="shadow-none border">
        <CardBody className="p-3">
          <div>
            <div className="float-end ms-2">
              <UncontrolledDropdown className="mb-2">
                <DropdownToggle className="font-size-16 text-muted" tag="a">
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setParentFolderId(folder.id);
                    }}
                  >
                    {/* Open */}
                    فتح
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setOpenModal(true);
                    }}
                  >
                    {/* Create SubFolder */}
                    إنشاء فرعي
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setCanRename(true);
                    }}
                  >
                    {/* Rename */}
                    إعادة تسمية
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setOpenUserModal(true);
                    }}
                  >
                    {/* Provide access */}
                    إعطاء صلاحية الوصول
                  </DropdownItem>

                  <div className="dropdown-divider"></div>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteFolder(
                        folder.id,
                        accessToken,
                        getFileFolders,
                        setLoading,
                        fetchRecentFiles
                      );
                    }}
                  >
                    {/* Remove */}
                    حذف
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div
              className="avatar-xs me-3 mb-3"
              onClick={async (e) => {
                e.preventDefault();
                setParentFolderId(folder.id);
              }}
            >
              <div className="avatar-title bg-transparent rounded">
                <i className="bx bxs-folder font-size-24 text-warning cursor-pointer"></i>
              </div>
            </div>
            <div className="d-flex">
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  <Link
                    to="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setParentFolderId(folder.id);
                    }}
                    className="text-body cursor-pointer"
                  >
                    {" "}
                    {!canRename ? (
                      folder.name
                    ) : (
                      <>
                        <input
                          type="text"
                          defaultValue={folder.name}
                          onChange={(e) => setUpdatedFolderName(e.target.value)}
                          style={{ width: "8rem", marginRight: "1rem" }}
                        />
                        <button
                          onClick={onRename}
                          className="rename-button"
                          style={{ border: 0 }}
                        >
                          {/* Rename */}
                          إعادة تسمية
                        </button>
                      </>
                    )}{" "}
                  </Link>
                </h5>
                {/* <p className="text-muted text-truncate mb-0">
                          {files.file} Files
                        </p>
                      </div>
                      <div className="align-self-end ms-2">
                        <p className="text-muted mb-0">{files.Gb}GB</p>
                      </div> */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <CreateFolder
        openModal={openModal}
        setOpenModal={setOpenModal}
        parent_id={folder.id}
        getFileFolders={getFileFolders}
      />
      <CreateUserAccessModal
        openUserModal={openUserModal}
        setOpenUserModal={setOpenUserModal}
        parent_id={folder?.id}
        getFileFolders={getFileFolders}
      />
    </Col>
  );
};

export default Folder;
