import { createSlice } from "@reduxjs/toolkit";

export const AssignSlice = createSlice({
  name: "assign",
  initialState: {
    signees: [] as { key: string; name: string; email: string }[],
  },
  reducers: {
    addSignee: (state, action) => {
      state.signees = [
        ...state.signees,
        {
          key: action.payload.key,
          name: action.payload.name,
          email: action.payload.email,
        },
      ];
    },
    resetSignee: (state) => {
      state.signees = [];
    },
  },
});

export const { addSignee, resetSignee } = AssignSlice.actions;

export const selectAssignees: any = (state) => state?.AssignUser?.signees;

export default AssignSlice.reducer;
