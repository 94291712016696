import React, { CSSProperties } from "react";
import { BigButton } from "./Bigbutton";

interface ConfirmOrCancelProps {
  onCancel: () => void;
  onConfirm: () => void;
  confirmTitle?: string;
  leftBlock?: React.ReactNode;
  hideCancel?: boolean;
  disabled?: boolean;
}

export function ConfirmOrCancel({
  onCancel,
  onConfirm,
  // confirmTitle = 'Confirm',
  confirmTitle = "تأكيد",
  leftBlock,
  hideCancel,
  disabled,
}: ConfirmOrCancelProps) {
  const styles: Record<string, CSSProperties> = {
    actions: {
      display: "flex",
      justifyContent: "space-between",
    },
    cancel: {
      marginRight: 8,
    },
  };

  return (
    <div style={styles.actions}>
      <div>{leftBlock}</div>
      <div>
        {!hideCancel ? (
          <BigButton
            // title={"Cancel"}
            title={"إلغاء"}
            style={styles.cancel}
            onClick={onCancel}
          />
        ) : null}
        <BigButton
          title={confirmTitle}
          inverted={true}
          onClick={onConfirm}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
