import React from 'react'
import { useNavigate } from 'react-router-dom'
// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Row,
} from 'reactstrap'

// import images
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from 'slices/thunk'
import { updateUserData } from 'slices/auth/login/reducer'

const ChangePassword = () => {
  //meta title
  // Change Password 
  document.title = '   تغيير كلمة المرور'

  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { accessToken } = useSelector((store: any) => store.Login)

  const { loading } = useSelector((store: any) => store.Login)

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('ادخل كلمة المرور'),
      // 'Please enter password'
      password_confirmation: Yup.string().required('الرجاء تأكيد كلمة المرور'
        // 'Please enter confirm password'
      ),
    }),
    onSubmit: async (values: any) => {
      try {
        const { password, password_confirmation } = values
        const response = await dispatch(
          changePassword({ password, password_confirmation, accessToken })
        )
        if (response.type === 'user/changePassword/fulfilled') {
          dispatch(updateUserData({ user_data: response.payload.data }))

          navigate('/')
        }
      } catch (error: any) {
        console.log('Error while change password : ', error)
      }
    },
  })

  return (
    <React.Fragment>
      {loading === true ? (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      ) : (
        ''
      )}

      {/* {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : ( */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle-self">
                  <Row>
                    <Col xs={7}>
                      <div className="bg-layout-text p-3">
                        <h5 className="bg-layout-text">
                           {/* Change Password */}
                           تغيير كلمة المرور
                           </h5>
                        <p>Signready</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal mt-5"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                      }}>
                      <div className="mb-3">
                        <Input
                          name="password"
                          className="form-control"
                          // placeholder="Enter password"
                          placeholder='ادخل كلمة المرور'
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Input
                          name="password_confirmation"
                          className="form-control"
                          // placeholder="Confirm Password"
                          placeholder='تأكيد كلمة المرور'
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password_confirmation || ''}
                          invalid={
                            validation.touched.password_confirmation &&
                            validation.errors.password_confirmation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password_confirmation &&
                        validation.errors.password_confirmation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password_confirmation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md " type="submit">
                          {/* Change Password */}
                          تغيير كلمة المرور
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  )
}

export default ChangePassword
