import React from 'react'
// import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import FileLeftBarChild from './FileLeftBarChild'

const FileLeftBar = (props: any) => {
  const { setParentFolderId, hierarchyFolders } = props

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              {hierarchyFolders &&
                hierarchyFolders.map((folder: any, key: number) => (
                  <FileLeftBarChild
                    key={key}
                    folder={folder}
                    setParentFolderId={setParentFolderId}
                  />
                ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default FileLeftBar
