import axios from "axios";
import { toast } from "react-toastify";


export const getAllPdfFiles = async (
  token: any,
  setAllPDf: any,
  dataFunction: any,

  setLoader: any
) => {
  try {
    // setLoader(true);

    const allPdf = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-document-list`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const allPDF = allPdf?.data?.data?.map((pdf: any) => {
      return {
        value: pdf.id,
        label: pdf.title,
      };
    });
    setAllPDf(allPDF);

    setLoader(false);
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const getAllUsers = async (
  token: any,
  setCustomer: any,
  dataFunction: any,

  setLoader: any
) => {
  try {
    const allUsers = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-users`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const userData = allUsers.data.data?.map((users: any) => {
      return {
        label: users.name,
        value: users.id,
        name: users.name,
      };
    });
    setCustomer(userData);

    setLoader(false);
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const viewPdf = async (
  token: any,
  dataFunction: any,
  setLoader: any,
  id: any,
  setProject: any,
  setUserList: any
) => {
  try {
    setLoader(true);

    const viewPdfData = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/view-assign-user-esignature/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    setProject(
      viewPdfData?.data?.data?.get_document?.map((document) => {
        return {
          label: document?.title,
          value: document?.id,
        };
      })
    );
    setUserList(
      viewPdfData?.data?.data?.get_user_ordered?.map((order) => {
        return {
          label: order?.user_email,
          value: order?.user_id,
        };
      })
    );

    setLoader(false);
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const getAllAssignPdf = async (
  token: any,
  setAllAssignPdf: any,
  dataFunction: any,
  isAdmin: any,
  setLoader: any,
  dispatch: any
) => {
  try {
    setLoader(true);

    const allAssignPdf = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-assign-list?isAdmin=${isAdmin}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    setAllAssignPdf(allAssignPdf?.data?.data);

    setLoader(false);
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};
export const deleteAssignedUser = async (
  token: any,
  id: any,
  setAllAssignPdf: any,
  dataFunction: any,
  isAdmin: any,

  setLoader: any,
  setDeleteModal: any,
  dispatch: any
) => {
  try {
    setLoader(true);
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/delete-assign-esignature/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    getAllAssignPdf(
      token,
      setAllAssignPdf,
      dataFunction,
      isAdmin,
      setLoader,
      dispatch
    );
    setDeleteModal(false);
    // toast.success("Assigned Signready Deleted Successfully");
    toast.success("تم الحذف بنجاح");
    setLoader(false);
  } catch (err: any) {
    setLoader(false);
    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const addPdfAssign = async (
  token: any,

  dataFunction: any,
  toggle: any,

  setLoader: any,
  project: any,
  userList: any,
  setProject: any,
  setUserList: any,
  setAllAssignPdf: any,
  isAdmin: any,
  dispatch: any
) => {
  try {
    setLoader(true);
    let arr: (string | undefined)[] = [];
    let order: (string | undefined)[] = [];
    for (let i = 0; i < userList.length; i++) {
      arr.push(userList[i]?.value);
      order.push(String(i));
    }
    const newAssignSignature = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/assign-user-esignature`,
      {
        document_id: project.value,
        assigned_user_id: arr,
        order_by_num: order,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    getAllAssignPdf(
      token,
      setAllAssignPdf,
      dataFunction,
      isAdmin,
      setLoader,
      dispatch
    );
    toast.success(newAssignSignature.data.message);
    setProject([]);
    setUserList([]);
    setLoader(false);
    toggle();
  } catch (error: any) {
    setLoader(false);
    if (error?.response?.data?.status === 401) {
      dataFunction();
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const addRemainder = async (
  token: any,
  setLoader: any,
  dataFunction: any,
  id: any
) => {
  try {
    setLoader(true);
    const remainderSend = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/reminder-send`,
      {
        assign_id: id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    toast.success(remainderSend?.data?.data);

    setLoader(false);
  } catch (error: any) {
    setLoader(false);
    if (error?.response?.data?.status === 401) {
      dataFunction();
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};
