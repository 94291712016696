import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { createFolder } from 'apiServices/files'
import { useSelector } from 'react-redux'
const CreateFolder = (props: any) => {
  const { openModal, setOpenModal, parent_id, getFileFolders } = props
  const [isLoading, setLoading] = useState(false)
  const { accessToken } = useSelector((store: any) => store.Login)
  // validation
  const toggle = () => {
    setOpenModal(!openModal)
  }
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      folderName: '',
    },

    validationSchema: Yup.object({
      folderName: Yup.string().required(
        // 'Please enter folder name'
        "الرجاء إدخال اسم المجلد"
      ),
    }),
    onSubmit: async (values: any) => {
      const { folderName } = values
     
      if (parent_id) {
        createFolder(
          { folderName, parent_id },
          accessToken,
          setLoading,
          setOpenModal,
          getFileFolders
        )
      } else {
        createFolder(
          values,
          accessToken,
          setLoading,
          setOpenModal,
          getFileFolders
        )
      }
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      ) : (
        <Modal toggle={toggle} isOpen={openModal}>
          <ModalHeader toggle={toggle} tag="h4">
            {/* Create Folder */}
            أنشئ مجلد
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e: any) => {
                e.preventDefault()
                validation.handleSubmit()

                return false
              }}>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>
                      {/* Folder name */}
                      إسم الملف
                    </Label>
                    <Input
                      name="folderName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.folderName || ''}
                      // placeholder="My Documents"
                      placeholder='مستنداتي'
                      invalid={
                        validation.touched.folderName &&
                        validation.errors.folderName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.folderName &&
                    validation.errors.folderName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.folderName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <Button
                      type="submit"
                      color="success"
                      className="save-customer">
                      {/* Add */}
                      إضافة
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default CreateFolder
