/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Renderer } from "./renderer";
import { DrawCall, MouseEvent } from "./pdfeditor";
import { GlobalWorkerOptions, PDFDocumentProxy, getDocument } from "pdfjs-dist";

import UserDocument from "../ViewDocumentUser/index";
import "react-toggle/style.css";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

// Setting worker path to worker bundle.
GlobalWorkerOptions.workerSrc =
  window.location.origin + "/assets/pdf.worker.js";
const MultiSelectcustomStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "red" : "#fff",
    backgroundColor: state.isSelected ? "#006cf9" : "#0c2144",
    "&:hover": {
      backgroundColor: "#006cf9",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0c2144",
    borderColor: "rgba(60 ,86 ,124, 50%)",
    "&:hover": {
      borderColor: "rgba(60 ,86 ,124, 50%)",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
  }),
};
interface ViewerProps {
  pdf: string;
  drawCalls: { [page: number]: DrawCall[] };
  eventHandler: (mouseEvent: MouseEvent) => void;
  setAddSignature: any;
  addSignature: any;

  signatureData: any;
  users: any;
  setUserInfo: any;
  email: any;
  usersInfo: any;
  setUserData: any;
  setSignatureData: any;
  setSelectedPages: any;
  adminSigantureData: any;
  selectedPages: any;
  typeSet: any;
  setType: Function;
  stateData: any;
  setPdf: any;
  allSignatureData: any;
  setAllSignatureData: any;
  pdfInfo: any;
}

const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {
  const maxZoom = 3;
  const minZoom = 0.5;
  const pdf = props.pdf;
  const { email } = useSelector((store: any) => store.Login.user);
  const [pdfDoc, setPDFDocumentProxy] = React.useState<
    PDFDocumentProxy | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [options, setOptions] = useState<any>([]);

  const toggle = () => setOpenModal(!openModal);

  const [pageCount, setPageCount] = React.useState<number>(0);

  const [zoom, setZoom] = React.useState<number>(1);

  const loadPdf = () => {
    if (!pdf) {
      return;
    }
    const dataPdf = pdf;
    getDocument(dataPdf)
      .promise.then((pdfDoc: PDFDocumentProxy) => {
        setPDFDocumentProxy(pdfDoc);
        setPageCount(pdfDoc.numPages);
        setCurrentPage(1);
      })
      .catch((err) => {
        alert(err.message);
        return null;
      });
    if (!pdfDoc) {
      return;
    }
  };
  const changePage = (dir: number) => {
    const newPage = currentPage + dir;
    if (newPage > pageCount || newPage <= 0) {
      return;
    }
    setCurrentPage(newPage);
  };

  const zoomChange = (dir: number) => {
    const newZoom = zoom + dir;
    if (newZoom >= maxZoom || newZoom <= minZoom) {
      return;
    }
    setZoom(newZoom);
  };

  React.useEffect(() => {
    loadPdf();
    // eslint-disable-next-line
  }, [pdf, props?.stateData]);

  useEffect(() => {
    const tempOptions: any = [];

    for (let i = 0; i < pageCount; i++) {
      tempOptions.push({
        value: i + 1,
        label: `page ${i + 1}`,
      });
    }

    setOptions(tempOptions);
  }, [pageCount]);

  return (
    <>
      <div className="relative">
        <div className="toolbar row justify-content-end">
          <div className="col-4 ">
            <div className="arrow--btn">
              <button
                className="btn btn-success page"
                id="prev_page"
                onClick={() => {
                  changePage(-1);
                }}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <span id="page_num">{currentPage}</span>
              <span>/</span>
              <span id="page_count">{pageCount}</span>
              <button
                className="btn btn-success page"
                id="next_page"
                onClick={() => {
                  changePage(1);
                }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div className="col-3">
            <div className="arrow--btn">
              <button
                className="btn btn-success zoom"
                id="zoom_in"
                onClick={() => zoomChange(0.1)}
              >
                <i className="fas fa-plus"></i>
              </button>
              <button
                className="btn btn-success zoom"
                id="zoom_out"
                onClick={() => zoomChange(-0.1)}
              >
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="btn-content">
          <div id="canvas_wrapper" className="canvas_wrapper">
            <Renderer
              drawCalls={props.drawCalls}
              pdf={pdfDoc}
              zoom={zoom}
              page={currentPage}
              eventHandler={props.eventHandler}
            />
          </div>

          <div className="row">
            <div className="" id="editor-side-bar">
              <div className="">
                <div className="btn-box">
                  <div className="add-sign-btn">
                    {props?.pdfInfo?.get_user_ordered?.some(
                      (order: any) =>
                        order.user_email === email &&
                        (order.status === 0 || order.status === false)
                    ) && (
                      <button
                        className="btn btn-success signature-btn custom-btn-sign"
                        onClick={() => {
                          props.setAddSignature(true);
                          props?.setUserInfo({
                            label: "",
                            value: "",
                          });
                          props?.setType({
                            label: "",
                            value: "",
                          });
                          props?.setSelectedPages([]);
                          setOpenModal(true);
                        }}
                      >
                        أضف تعليقك
                      </button>
                    )}
                    <UserDocument
                      setPdfData={props?.setPdf}
                      pdf={pdf}
                      allSignatureData={props?.allSignatureData}
                      setAllSignatureData={props?.setAllSignatureData}
                    />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          toggle={toggle}
          isOpen={openModal}
          handleclose={() => {
            props?.setAddSignature(false);
          }}
        >
          <ModalHeader toggle={toggle} tag="h4">
            {/* Assign Users */}
            أضف تعليقك
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e: any) => {
                e.preventDefault();

                return false;
              }}
            >
              <Row>
                <Col xs={12}></Col>
                {/* <label>البريد الإلكتروني:</label> */}
                <label>تعليقات</label>
                <textarea
                  style={{
                    width: "95%",
                    margin: "auto",
                  }}
                  cols={40}
                  rows={5}
                  onChange={(e) => {
                    props.email.current = e.target.value;
                  }}
                />
              </Row>

              <div className="mb-3 mt-3">
                <Label>
                  {/* Select Page */}
                  اختر الصفحة
                </Label>
                <Select
                  styles={MultiSelectcustomStyles}
                  options={options}
                  isMulti
                  onChange={(e) => {
                    props?.setSelectedPages(e);
                  }}
                />
              </div>

              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      className="btn btn-success"
                      onClick={(e) => {
                        const newSignatureData = {
                          email: props.email.current,
                        };
                        // eslint-disable-next-line
                        {
                          if (props?.selectedPages.length !== 0) {
                            for (
                              let i = 0;
                              i < props?.selectedPages.length;
                              i++
                            ) {
                              props.setUserData((prevArray: any) => [
                                ...prevArray,
                                props.usersInfo.value,
                              ]);
                            }
                          } else {
                            props.setUserData((prevArray: any) => [
                              ...prevArray,
                              props.usersInfo.value,
                            ]);
                          }
                        }
                        props.setSignatureData(newSignatureData);
                        props.setUserInfo({
                          label: "",
                          value: "",
                        });
                        toggle();
                        props.adminSigantureData(
                          props?.selectedPages,
                          props.email.current,
                          props.usersInfo.value,
                          null
                        );
                      }}
                    >
                      {/* Save */}
                      حفظ
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        props.setAddSignature(false);
                        toggle();
                      }}
                    >
                      {/* Cancel */}
                      إلغاء
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      {/* initialModal */}
    </>
  );
};

export { Viewer };
