import React, { CSSProperties, useRef } from "react";
// import { primary45 } from "../../src/pages/Utility/colors";
import useHover from "../hooks/index";

interface BigButtonProps {
  title: string;
  onClick: () => void;
  inverted?: boolean;
  fullWidth?: boolean;
  customFillColor?: string;
  customWhiteColor?: string;
  style?: CSSProperties;
  noHover?: boolean;
  id?: string;
  small?: boolean;
  disabled?: boolean;
  marginRight?: number;
}

export function BigButton({
  title,
  onClick,
  inverted = false,
  fullWidth = false,
  customFillColor,
  customWhiteColor,
  style,
  noHover = false,
  id,
  small = false,
  disabled = false,
  marginRight,
}: BigButtonProps) {
  const hoverRef = useRef<HTMLDivElement>(null);
  const [isHovered] = useHover();

  let fillColor = customFillColor || "#00888d";
  const whiteColor = customWhiteColor || "#FFF";

  let initialBg: string | null = null;
  let hoverBg: string | null = fillColor;

  let initialColor = fillColor;
  let hoverColor = whiteColor;

  if (inverted) {
    initialBg = fillColor;
    hoverBg = null;
    initialColor = whiteColor;
    hoverColor = fillColor;
  }

  if (disabled) {
    initialBg = "#ddd";
    hoverBg = "#ddd";
    fillColor = "#ddd";
  }

  const styles: any = {
    container: {
      display: "inline-flex",
      // minWidth: "175px",
      alignItems: "center",
      justifyContent: "center",
      width: fullWidth ? "100%" : undefined,
      backgroundColor: isHovered && !noHover ? hoverBg : initialBg,
      color:
        isHovered && !noHover && !disabled
          ? hoverColor
          : disabled
          ? "#999"
          : initialColor,
      borderRadius: 4,
      padding: small ? "2px 4px" : "6px 8px",
      fontSize: small ? 14 : undefined,
      // border: `1px solid ${fillColor}`,
      cursor: !disabled ? "pointer" : undefined,
      userSelect: "none",
      boxSizing: "border-box",
      marginRight,
    },
  };

  return (
    <div
      id={id}
      ref={noHover ? undefined : hoverRef}
      style={{ ...styles.container, ...style }}
      // onMouseEnter={handleMouseEnter as MouseEventHandler<HTMLDivElement>}
      // onMouseLeave={handleMouseLeave as MouseEventHandler<HTMLDivElement>}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <div>{title}</div>
    </div>
  );
}
