import React from "react";
// { useState }
// import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  // Collapse,
  DropdownItem,
  DropdownMenu,
  // DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import FileLeftBarChild from "./FileLeftBarChild";

const FileLeftBar = (props: any) => {
  const {
    parentFolderId,
    setParentFolderId,
    hierarchyFolders,
  } = props;

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <div className="mb-3">
                <UncontrolledDropdown>
                  <DropdownMenu>
                    <DropdownItem
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setOpenModal(true);
                    // }}
                    >
                      <i className="bx bx-folder me-1"></i>
                      {/* Folder */}
                      مجلد
                    </DropdownItem>
                    {parentFolderId && (
                      <DropdownItem
                        href="#"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   setOpenFileModal(true);
                        // }}
                      >
                        <i className="bx bx-file me-1"></i>
                        {/* File */}
                        ملف
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {hierarchyFolders &&
                hierarchyFolders.map((folder: any, key: number) => (
                  <FileLeftBarChild
                    key={key}
                    folder={folder}
                    setParentFolderId={setParentFolderId}
                  />
                ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FileLeftBar;
