import React from "react";
import { BigButton } from "./Bigbutton";
import { primary45 } from "../../src/pages/Utility/colors";

interface PagingControlProps {
  totalPages: number;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}

const PagingControl: React.FC<PagingControlProps> = ({ totalPages, pageNum, setPageNum }) => {
  const styles = {
    container: {
      marginTop: 8,
      marginBottom: 8,
    },
    inlineFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pageInfo: {
      padding: 8,
      color: primary45,
      fontSize: 14,
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.inlineFlex}>
        <BigButton
          title={"<"}
          onClick={() => setPageNum(prevPageNum => prevPageNum - 1)}
          disabled={pageNum - 1 === -1}
        />
        <div style={styles.pageInfo}>
          Page: {pageNum + 1}/{totalPages}
        </div>
        <BigButton
          title={">"}
          onClick={() => setPageNum(prevPageNum => prevPageNum + 1)}
          disabled={pageNum + 1 > totalPages - 1}
        />
      </div>
    </div>
  );
};

export default PagingControl;
