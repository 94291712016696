/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

// import Component
import FileLeftBar from "./FileLeftBar";
import FileList from "./FileList";
import {
  getMyHierarchy,
  getMyFiles,
} from "apiServices/files";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Index = () => {
  //meta title
  document.title = "File Manager | ESignature";

  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { accessToken } = useSelector((store: any) => store.Login);
  const [parentFolderId, setParentFolderId] = useState(null);
  const [currentPath, setCurrentPath] = useState<Array<any>>([]);
  const [hierarchyFolders, setHierarchyFolders] = useState<any>([]);

  const fetchHierarchyFolders = async () => {
    try {
      setLoading(true);
      await getMyHierarchy(setHierarchyFolders, accessToken);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getFileFolders = async () => {
    try {
      setLoading(true);
      fetchHierarchyFolders();
      const fileFolders = await getMyFiles(accessToken, parentFolderId);
      setLoading(false);
      setFiles(fileFolders.files);
      setFolders(fileFolders.folders);
    } catch (error) {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchHierarchyFolders();
  }, []);

  useEffect(() => {
    if (accessToken) {
      getFileFolders();
    }

    if (hierarchyFolders) {
      if (parentFolderId === null) {
        setCurrentPath([{ title: "Files", id: null }]);
      }
      const path: any = [];
      const findFolder = (folders, folderId) => {
        for (const folder of folders) {
          if (folder.id === folderId) {
            return {
              title: folder.name,
              id: folder.id,
              parent_id: folder.parent_id,
            };
          }
          if (folder.folder_children.length) {
            const response = findFolder(folder.folder_children, folderId);
            if (response !== null) {
              return response; // Return the result of the recursive call if it's not null
            }
          }
        }
        return null; // Return null if the folder is not found in this branch
      };

      const findMyCurrentPath = (parentFolderId) => {
        const folder = findFolder(hierarchyFolders, parentFolderId);
        path.unshift({ title: folder?.title, id: folder?.id });
        if (folder?.parent_id) {
          findMyCurrentPath(folder.parent_id);
        }
      };
      findMyCurrentPath(parentFolderId);
      setCurrentPath(path);
    }
  }, [parentFolderId]);

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <div className="loader-box">
            <div className="loader"></div>
          </div>
        ) : (
          <Container fluid>
            <Row>
              <Col className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4
                    className="mb-sm-0 font-size-18 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setParentFolderId(null);
                    }}
                  >
                    {/* File Manager */}
                    إدارة الملفات
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link
                          to="#"
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            setParentFolderId(null);
                          }}
                        >
                          {/* File Manager */}
                          إدارة الملفات
                        </Link>
                      </BreadcrumbItem>
                      {currentPath.map((path) => {
                        return (
                          <BreadcrumbItem>
                            <Link
                              to="#"
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                setParentFolderId(path.id);
                              }}
                            >
                              {path?.title}
                            </Link>
                          </BreadcrumbItem>
                        );
                      })}
                      {/* <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem> */}
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-xl-flex">
              <div className="w-100">
                <div className="d-md-flex">
                  <FileLeftBar
                    getFileFolders={getFileFolders}
                    parentFolderId={parentFolderId}
                    setParentFolderId={setParentFolderId}
                    hierarchyFolders={hierarchyFolders}
                  />
                  <div className="w-100">
                    <Card>
                      <CardBody>
                        <FileList
                          getFileFolders={getFileFolders}
                          files={files}
                          folders={folders}
                          parentFolderId={parentFolderId}
                          setParentFolderId={setParentFolderId}
                          currentPath={currentPath}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};
export default Index;
