import React from "react";

import {
  Col,
  // Form,
  Row,
} from "reactstrap";
import Folder from "./Folder";
import File from "./File";

const FileList = (props: any) => {
  const {
    files,
    folders,
    getFileFolders,
    parentFolderId,
    setParentFolderId,
    fetchRecentFiles,
    // currentPath,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>
                ملفاتي
                {/* My Files */}
              </h5>
            </div>
          </Col>
          <Col>
            <div className="py-4">
              {/* {currentPath.map(dir => (
                <button
                  className="border-0 bg-transparent mx-0 pl-1"
                  onClick={e => {
                    e.preventDefault()
                    setParentFolderId(dir.id)
                  }}>
                  {dir.title} &gt;{' '}
                </button>
              ))} */}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {folders &&
            folders.map((folder, key: number) => {
              return (
                <Folder
                  folder={folder}
                  getFileFolders={getFileFolders}
                  key={key}
                  setParentFolderId={setParentFolderId}
                  fetchRecentFiles={fetchRecentFiles}
                />
              );
            })}
          {parentFolderId &&
            files &&
            files.map((file, key: number) => {
              return (
                <>
                  <File
                    file={file}
                    getFileFolders={getFileFolders}
                    key={key}
                    parentFolderId={parentFolderId}
                    fetchRecentFiles={fetchRecentFiles}
                  />
                </>
              );
            })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default FileList;
