// import { deleteFolder, renameFolder } from "apiServices/files";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DeleteAccessModal from "./DeleteAccessModal";
import { removeFolderAccess } from "apiServices/files";
import { useSelector } from "react-redux";

const Folder = (props: any) => {
  const { folder, setParentFolderId, userId, onCloseClick } = props;
  const [folderId, setFolderId] = useState();
  // getFileFolders
  const { accessToken } = useSelector((store: any) => store.Login);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = useCallback(async () => {
    try {
      await removeFolderAccess(
        folderId,
        userId,
        accessToken,
        onCloseClick,
        onClose,
        setLoading
      );
    } catch (error) {}
    // eslint-disable-next-line
  }, [folderId, accessToken]);

  const onClose = () => {
    setDeleteModal(false);
  };
  return (
    <Col xl={4} sm={6}>
      {isLoading === true ? (
        ""
      ) : (
        <>
          {/* <div className="loader-box">
            <div className="loader"></div>
          </div> */}
        </>
      )}
      <Card className="shadow-none border">
        <CardBody className="p-3">
          <div>
            <div className="float-end ms-2">
              <UncontrolledDropdown className="mb-2">
                <DropdownToggle className="font-size-16 text-muted" tag="a">
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setParentFolderId(folder?.id);
                    }}
                  >
                    {/* Open */}
                    يفتح
                  </DropdownItem>

                  {folder?.parent_id === null && (
                    <DropdownItem
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();
                        setDeleteModal(true);
                        setFolderId(folder?.id);
                      }}
                    >
                      {/* Remove access */}
                      إزالة الوصول
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div
              className="avatar-xs me-3 mb-3"
              onClick={async (e) => {
                e.preventDefault();
                setParentFolderId(folder?.id);
              }}
            >
              <div className="avatar-title bg-transparent rounded">
                <i className="bx bxs-folder font-size-24 text-warning cursor-pointer"></i>
              </div>
            </div>
            <div className="d-flex">
              <div className="overflow-hidden me-auto">
                <h5 className="font-size-14 text-truncate mb-1">
                  <Link
                    to="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setParentFolderId(folder?.id);
                    }}
                    className="text-body cursor-pointer"
                  >
                    {folder?.name}
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <DeleteAccessModal
        // folderId={folderId}
        // setFolderId={setFolderId}
        // setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        onDeleteClick={onClickDelete}
        onClose={onClose}
        onCloseClick={onCloseClick}
      />
    </Col>
  );
};

export default Folder;
