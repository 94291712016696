/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import fontkit from "@pdf-lib/fontkit";
import {
  getAssignedUser,
  saveAssignSignaturepdf,
} from "../../apiServices/addpdf/index";
import { logOutToken } from "slices/auth/login/reducer";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { blobToURL } from "pages/Utility/Utils";

import { AddSigDialog } from "../../Components/AddSigDialog";
import { pdfPermission } from "hooks/pdfPermission";
import { toast } from "react-toastify";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface viewPdf {
  pdf: any;
  setPdfData: any;
  allSignatureData: any;
  setAllSignatureData: any;
}

const Index = (props: viewPdf) => {
  const sigRef: any = useRef(null);
  const [imageBase64, setImage] = useState("");
  const [test, setTest] = useState(false);
  const { email } = useSelector((store: any) => store.Login.user);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const document_id = searchParams.get("id");

  const [pageDetails, setPageDetails] = useState<any>(null);
  const [pdfInfo, setPdf] = useState<any>();
  const [autoDate, setAutoDate] = useState(true);
  const [signatureURL, setSignatureURL] = useState<any>(null);
  const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
  const [statepdfUrl, setpdfurl] = useState(false);

  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [pdfData, setPdfDataInfo] = useState("");
  const [stateData, setStateData] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [permission, setPermission] = useState(true);
  const [initialText, setInitialText] = useState("");
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  const documentRef: any = useRef(null);
  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAssignedUser(
      accessToken,
      id,
      setPdf,
      setLoader,
      logoutFunction,
      props.setPdfData,
      setStateData
    ); // eslint-disable-next-line
  }, []);

  const getData = async (uint8Array: any) => {
    setPdfDataInfo(uint8Array);
    const pdfDoc = await PDFDocument.load(uint8Array, {
      ignoreEncryption: true,
    });
    pdfDoc.registerFontkit(fontkit);

    const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
    const fontBytes = await fetch(urldata).then((res) => res.arrayBuffer());

    const customFont = await pdfDoc.embedFont(fontBytes, {
      subset: true,
    });
    const pages = pdfDoc.getPages();

    for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
      if (
        (pdfInfo?.get_user_ordered[i]?.status === false ||
          pdfInfo?.get_user_ordered[i]?.status === 0) &&
        pdfInfo?.get_user_ordered[i].user_email === email
      ) {
        const x = pdfInfo.box_position[i]["x"];
        const y =
          pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
          pdfInfo.box_position[i]["y"] -
          25;

        const width = pdfInfo.box_size[i]["width"];
        const height = pdfInfo.box_size[i]["height"];

        // page_index
        pages[pdfInfo?.page_index[i] - 1]?.drawRectangle({
          x,
          y,
          width,
          height,
          // borderColor: rgb(0.2, 0.8, 0.2),
          borderColor: rgb(0.1098, 0.2745, 0.5019),
          borderWidth: 2,
          color: rgb(0.9, 0.9, 0.9),
        });
        var text;
        if (pdfInfo?.signature_type[i] === "initials") {
          text = "التأشير";
        } else {
          text = pdfInfo?.get_user_ordered[i].user_name;
        }
        const fontSize = 8;
        const textColor = rgb(0, 0, 0.9); // Blue

        // Calculate the position to center the text within the box
        const textX = x + width / 2 - (text?.length * fontSize) / 4;
        const textY = y + height / 2 - fontSize / 2;

        // Draw the text on the page
        pages[pdfInfo?.page_index[i] - 1]?.drawText(text, {
          x: textX,
          y: textY,
          color: textColor,
          font: customFont,
          size: 10,
        });
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);

    const URL: any = await blobToURL(blob);

    props?.setPdfData(URL);
  };
  const base64ToUint8Array = (base64WithoutPrefix: any) => {
    const binaryString = atob(base64WithoutPrefix);

    const length = binaryString.length;
    const uint8Array: any = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    getData(uint8Array);
    return uint8Array;
  };
  useEffect(() => {
    if (stateData === true) {
      const base64WithoutPrefix: any = props.pdf.replace(
        /^data:application\/pdf;base64,/,
        ""
      );

      base64ToUint8Array(base64WithoutPrefix);
    }
    // eslint-disable-next-line
  }, [stateData]);

  const saveDocumentFunction = () => {
    // if (signatureURL) {
    saveAssignSignaturepdf(
      accessToken,
      setLoader,
      logoutFunction,
      props?.pdf,
      id,
      document_id,
      navigate,
      ipAddress
    );
    // } else {
    //   toast.error("Please Add the Signature First");
    // }
  };

  const setSignature = async (url?: any) => {
    const fontSize = 20;

    if (pdfInfo?.signature_type?.includes("initials")) {
      if (!initialText) {
        toast.error("الرجاء إضافة التأشير");
        return;
      }
    }
    if (test) {
      setSignatureURL(url);
      // const scale = originalWidth / documentRef.current.clientWidth;

      const pdfDoc = await PDFDocument.load(pdfData);
      pdfDoc.registerFontkit(fontkit);

      const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
      const fontBytes = await fetch(urldata).then((res) => res.arrayBuffer());

      const customFont = await pdfDoc.embedFont(fontBytes, {
        subset: true,
      });

      const pages = pdfDoc.getPages();
      const pngImage = await pdfDoc.embedPng(url);
      // const pngDims = pngImage.scale(scale * 0.3);
      let newXData: any, newYData: any;
      for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
        //eslint-disable-next-line
        {
          if (pdfInfo?.get_user_ordered[i].user_email === email) {
            newXData = pdfInfo.box_position[i]["x"];
            newYData =
              pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
              pdfInfo.box_position[i]["y"] -
              25;
            if (pdfInfo?.signature_type[i] !== "initials") {
              pages[pdfInfo?.page_index[i] - 1].drawImage(pngImage, {
                x: newXData,
                y: newYData,
                width: 50,
                height: 50,
              });
            }
            if (pdfInfo?.signature_type[i] === "initials") {
              pages[pdfInfo?.page_index[i] - 1].drawText(initialText, {
                x: newXData,
                y: newYData,
                font: customFont,
                size: fontSize,
              });
            }
          }
        }
      }
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([new Uint8Array(pdfBytes)]);
      const URL: any = await blobToURL(blob);
      props?.setPdfData(URL);

      setSignatureURL(null);
      setpdfurl(true);
      setSignatureDialogVisible(false);
    } else {
      if (!pdfInfo?.signature_type.includes("")) {
        if (initialText) {
          setSignatureURL(url);

          const pdfDoc = await PDFDocument.load(pdfData);
          pdfDoc.registerFontkit(fontkit);

          const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
          const fontBytes = await fetch(urldata).then((res) =>
            res.arrayBuffer()
          );

          const customFont = await pdfDoc.embedFont(fontBytes, {
            subset: true,
          });
          const pages = pdfDoc.getPages();
          const timesRomanFont = await pdfDoc.embedFont(
            StandardFonts.CourierBold
          );
          let newXData: any, newYData: any;
          for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
            //eslint-disable-next-line
            {
              if (pdfInfo?.get_user_ordered[i].user_email === email) {
                newXData = pdfInfo.box_position[i]["x"];
                newYData =
                  pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
                  pdfInfo.box_position[i]["y"] -
                  25;
                pages[pdfInfo?.page_index[i] - 1].drawText(initialText, {
                  x: newXData,
                  y: newYData,
                  font: customFont,
                  size: fontSize,
                });
              }
            }
          }
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([new Uint8Array(pdfBytes)]);
          const URL: any = await blobToURL(blob);
          props?.setPdfData(URL);

          setpdfurl(true);
          setSignatureDialogVisible(false);
        } else {
          toast.error("الرجاء إضافة التأشير");
        }
      } else {
        if (sigRef.current && pdfInfo?.signature_type.includes("")) {
          if (sigRef.current?.isEmpty() === false) {
            setSignatureURL(url);
            // const scale = originalWidth / documentRef.current.clientWidth;
            const pdfDoc = await PDFDocument.load(pdfData);
            pdfDoc.registerFontkit(fontkit);

            const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
            const fontBytes = await fetch(urldata).then((res) =>
              res.arrayBuffer()
            );

            const customFont = await pdfDoc.embedFont(fontBytes, {
              subset: true,
            });
            const timesRomanFont = await pdfDoc.embedFont(
              StandardFonts.CourierBold
            );
            const pages = pdfDoc.getPages();
            const pngImage = await pdfDoc.embedPng(url);
            // const pngDims = pngImage.scale(scale * 0.3);
            let newXData: any, newYData: any;
            for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
              //eslint-disable-next-line
              {
                if (pdfInfo?.get_user_ordered[i].user_email === email) {
                  newXData = pdfInfo.box_position[i]["x"];
                  newYData =
                    pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
                    pdfInfo.box_position[i]["y"] -
                    25;
                  if (pdfInfo?.signature_type[i] !== "initials") {
                    pages[pdfInfo?.page_index[i] - 1].drawImage(pngImage, {
                      x: newXData,
                      y: newYData,
                      width: 50,
                      height: 50,
                    });
                  }
                  if (pdfInfo?.signature_type[i] === "initials") {
                    pages[pdfInfo?.page_index[i] - 1].drawText(initialText, {
                      x: newXData,
                      y: newYData,
                      font: customFont,
                      size: fontSize,
                    });
                  }
                }
              }
            }
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([new Uint8Array(pdfBytes)]);
            const URL: any = await blobToURL(blob);
            props?.setPdfData(URL);
            setSignatureURL(null);
            setpdfurl(true);
            setSignatureDialogVisible(false);
          } else {
            toast.error("فضلا إضافة التوقيع أولاً ");
          }
        }
      }
    }
  };

  const getIp = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => {
        return response.json();
      })
      .then((res: any) => {
        setIpAddress(res?.ip);
      })
      .catch((err: any) => console.error("Problem fetching my IP", err));
  };

  const permissionPayment = () => {
    // setPermission
    pdfPermission(pdfInfo, email, setPermission);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    permissionPayment();
    //eslint-disable-next-line
  }, [pdfInfo]);

  const permissionSignatureFunction = (permissionData: any, url: any) => {
    if (permissionData === true) {
      if (permission === true) {
        setSignatureDialogVisible(true);
      } else {
        // toast.error("Based on Heirarchy you are not able to do Signature");
        toast.error(" التوقيع تسلسلي ولا يمكنك التوقيع في الوقت الحالي");
      }
    } else {
      if (permission === true) {
        if (url === true) {
          if (Object.entries(props?.allSignatureData).length === 0) {
            saveDocumentFunction();
          } else {
            toast.error("أولا قم بحفظ التعليقات");
          }
        } else {
          if (
            pdfInfo?.signature_type?.includes("") &&
            pdfInfo?.signature_type?.includes("initials")
          ) {
            toast.error("قم أولاً بإضافة الأحرف الأولى والتوقيع");
          } else if (pdfInfo?.signature_type?.includes("")) {
            toast.error("فضلاً إضافة التوقيع");
          } else {
            toast.error("قم أولاً بإضافة التأشير");
          }

          // toast.error("First Add Signature");
        }
      } else {
        // toast.error("Based on Heirarchy you are not able to do Signature");
        toast.error(" التوقيع تسلسلي ولا يمكنك التوقيع في الوقت الحالي");
      }
    }
  };
  const splitTextIntoLines = (text, font, fontSize, maxWidth) => {
    const lines: any = [];
    const words = text.split("");
    let currentLine = "";

    words.forEach((word) => {
      const testLine = currentLine + (currentLine ? "" : "") + word;
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth && currentLine) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const savePdf = async () => {
    try {
      if (Object.entries(props?.allSignatureData).length > 0) {
        const pdfDoc = await PDFDocument.load(props.pdf, {
          ignoreEncryption: true,
        });
        const pdfDoc1 = await PDFDocument.load(pdfData, {
          ignoreEncryption: true,
        });
        pdfDoc.registerFontkit(fontkit);
        pdfDoc1.registerFontkit(fontkit);
        const urldata = `${process.env.REACT_APP_FONT_URL}/HelveticaWorld-Regular.ttf`;
        const fontBytes = await fetch(urldata).then((res) => res.arrayBuffer());

        const customFont = await pdfDoc.embedFont(fontBytes, {
          subset: true,
        });
        const customFont1 = await pdfDoc1.embedFont(fontBytes, {
          subset: true,
        });
        const pages = pdfDoc.getPages();
        const pages1 = pdfDoc1.getPages();

        if (Object.entries(props?.allSignatureData).length > 0) {
          for (const [pageNumber, signatures] of Object.entries(
            props?.allSignatureData
          )) {
            const pageNumberForm: any = Number(pageNumber) - 1;
            // Removed the variable pageNumberFound, as pageNumber can be used directly
            const signaturesArray = signatures as any[];
            if (signatures) {
              signaturesArray.forEach(async (signature: any, index) => {
                const x = signature?.textDrawCall?.position.x;
                const y =
                  pages[pageNumberForm]?.getSize().height -
                  signature?.textDrawCall?.position.y -
                  25;
                const maxWidth = 300; // Set maximum width for wrapping
                const fontSize = 10;

                let currentY = y;
                const lines = splitTextIntoLines(
                  signature?.email,
                  customFont,
                  fontSize,
                  maxWidth
                );
                lines.forEach((line) => {
                  pages[pageNumberForm].drawText(line, {
                    x,
                    y: currentY + 20,
                    size: fontSize,
                    font: customFont,
                    color: rgb(0, 0, 0),
                  });
                  pages1[pageNumberForm].drawText(line, {
                    x,
                    y: currentY + 20,
                    size: fontSize,
                    font: customFont1,
                    color: rgb(0, 0, 0),
                  });

                  currentY -= fontSize * 1.2; // Adjust line spacing
                });

                const pdfBytes = await pdfDoc.save();
                const pdfBytes1 = await pdfDoc1.save();
                const blob = new Blob([new Uint8Array(pdfBytes)]);
                const blob1 = new Blob([new Uint8Array(pdfBytes1)]);
                const URL: any = await blobToURL(blob);
                const URL1: any = await blobToURL(blob1);
                props.setAllSignatureData([]);
                props.setPdfData(URL);
                setPdfDataInfo(URL1);
              });
            }
          }
        }
      } else {
        toast.error("قم أولاً بإضافة التعليق");
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="pdf-wrap">
        {/* <ToastContainer /> */}
        {loader === true ? (
          <div className="loader-box">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {signatureDialogVisible ? (
              <AddSigDialog
                autoDate={autoDate}
                setAutoDate={setAutoDate}
                onClose={() => setSignatureDialogVisible(false)}
                onConfirm={(url: any) => {
                  console.log("Confirm");

                  setSignatureURL(url);
                  setSignature(url ? url : "");
                }}
                setSignature={setSignature}
                accessToken={accessToken}
                logoutFunction={logoutFunction}
                setLoader={setLoader}
                sigRef={sigRef}
                imageBase64={imageBase64}
                setImage={setImage}
                test={test}
                setTest={setTest}
                signatureType={pdfInfo?.signature_type}
                setInitialText={setInitialText}
              />
            ) : null}

            {pdfInfo?.get_user_ordered?.some(
              (order: any) =>
                order.user_email === email &&
                (order.status === 0 || order.status === false)
            ) && (
              <>
                <div className="signature-wrap">
                  <button
                    onClick={() => {
                      savePdf();
                    }}
                    className="btn btn-success signature-btn"

                    // title={"Add signature"}
                  >
                    حفظ تعليقك
                  </button>

                  <button
                    onClick={() => {
                      permissionSignatureFunction(true, statepdfUrl);
                      setTest(false);
                    }}
                    className="btn btn-success signature-btn"
                  >
                    <div>
                      {pdfInfo?.signature_type?.includes("") &&
                      pdfInfo?.signature_type?.includes("initials")
                        ? "إضافة التأشير والتوقيع"
                        : pdfInfo?.signature_type?.includes("")
                        ? "إضافة التوقيع"
                        : pdfInfo?.signature_type?.includes("initials")
                        ? "إضافة التأشير"
                        : ""}
                    </div>
                  </button>

                  {pdfInfo?.signature_type.includes("") && (
                    <div style={{ textAlign: "right" }}>
                      {pdfInfo?.get_user_ordered?.some(
                        (order: any) =>
                          order.user_email === email &&
                          (order.status === 0 || order.status === false)
                      ) && <span className="sign-pdf">تحتاج إلى توقيع</span>}
                      {pdfInfo?.get_user_ordered?.some(
                        (order: any) =>
                          order.user_email === email &&
                          (order.status === 0 || order.status === false)
                      ) &&
                        pdfInfo?.page_index &&
                        // eslint-disable-next-line
                        pdfInfo?.page_index?.map((page, index) => {
                          if (pdfInfo?.signature_type[index] === "") {
                            return (
                              <p
                                style={{
                                  marginBottom: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                رقم الصفحة. {page}
                              </p>
                            );
                          }
                        })}
                    </div>
                  )}
                  {pdfInfo?.signature_type.includes("initials") && (
                    <div style={{ textAlign: "right" }}>
                      {pdfInfo?.get_user_ordered?.some(
                        (order: any) =>
                          order.user_email === email &&
                          (order.status === 0 || order.status === false)
                      ) && (
                        <span className="sign-pdf">
                          تحتاج إلى إضافة التأشير
                        </span>
                      )}
                      {pdfInfo?.get_user_ordered?.some(
                        (order: any) =>
                          order.user_email === email &&
                          (order.status === 0 || order.status === false)
                      ) &&
                        // eslint-disable-next-line
                        pdfInfo?.page_index &&
                        // eslint-disable-next-line
                        pdfInfo?.page_index?.map((page, index) => {
                          if (pdfInfo?.signature_type[index] === "initials") {
                            return (
                              <p
                                style={{
                                  marginBottom: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                رقم الصفحة. {page}
                              </p>
                            );
                          }
                        })}
                    </div>
                  )}
                  <button
                    onClick={() => {
                      permissionSignatureFunction(false, statepdfUrl);
                    }}
                    className="btn btn-success signature-btn"
                  >
                    أكمل التوقيع
                  </button>
                </div>
                {/* )
              : ""} */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Index;
