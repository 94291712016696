export const formatDate = (inputDateString:any) => {
    const inputDate = new Date(inputDateString);
  
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(inputDate);
    const year = inputDate.getFullYear();
  
    const formattedDate = `${day} ${month} ${year}`;
  
    return formattedDate;
  };