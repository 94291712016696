import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const AuthProtected = (props) => {
  const { accessToken }: any = useSelector((store: any) => store.Login);

  if (!accessToken) {
    return <Navigate to={{ pathname: "/login" }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
