import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import RemoveAccessModal from "../../../Components/Common/RemoveAccessModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  deleteUser,
  getAllUsers,
  createUser,
  updateUser,
} from "../../../apiServices/users/index";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import { logOutToken } from "slices/auth/login/reducer";
import { ToastContainer } from "react-toastify";

const EcommerceCustomers = () => {
  //meta title
  document.title = "Customers | ESignature ";

  const dispatch: any = useDispatch();
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [customer, setCustomer] = useState<any>([]);

  const [userId, setUserId] = useState("");
  const [singleCustomer, setSingleCustomer] = useState({
    id: "",
    name: "",
    email: "",
    isAdmin: "",
  });

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (singleCustomer && singleCustomer.name) || "",
      email: (singleCustomer && singleCustomer.email) || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required(
        // "Please Enter Your Name"
        " لرجاء إدخال الاسم"
      ),
      email: Yup.string().email().required("الرجاء ادخال البريد الإلكتروني"),
      // "Please Enter Your Email"),
    }),
    onSubmit: (values: any) => {
      const newCustomer = {
        id: singleCustomer.id,
        name: values["name"],
        email: values["email"],
        role: singleCustomer?.isAdmin ? singleCustomer?.isAdmin : "0",
      };
      setSingleCustomer({
        id: singleCustomer.id,
        name: values["name"],
        email: values["email"],
        isAdmin: singleCustomer?.isAdmin ? singleCustomer?.isAdmin : "0",
      });

      !isEdit
        ? // save new customer
          createUser(
            newCustomer,
            accessToken,
            setCustomer,
            logoutFunction,
            toggle,
            setLoader
          )
        : updateUser(
            accessToken,
            newCustomer,
            logoutFunction,
            toggle,
            setCustomer,

            setLoader
          );

      validation.resetForm();
    },
  });

  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };

  const handleCustomerClick = useCallback(
    (arg: any) => {
      const customer = arg;

      setSingleCustomer({
        id: customer.id,
        name: customer.name,
        email: customer.email,
        isAdmin: customer.isAdmin,
      });

      setIsEdit(true);

      toggle();
    },
    [toggle]
  );

  // Customber Column
  const columns = useMemo(
    () => [
      // {
      //   header: '#',
      //   accessorKey: 'id',
      //   cell: () => {
      //     return <input type="checkbox" className="form-check-input" />
      //   },
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        // header: "Name",
        header: "الاسم",
        accessorKey: "name",

        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        // header: "Role",
        header: "نوع المستخدم",
        accessorKey: "isAdmin",
        cell: (cell: any) => {
          return (
            <>
              <p className="mb-0">
                {/* {cell.row.original.isAdmin ? "Admin" : "User"} */}
                {cell.row.original.isAdmin ? "مدير" : "مستخدم"}
              </p>
            </>
          );
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        // header: "Email",
        header: "الايميل        ",
        accessorKey: "email",

        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return (
            <>
              <p className="mb-0">{cell.row.original.email}</p>
            </>
          );
        },
      },

      {
        // header: "Action",
        header: "إعدادات",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                {" "}
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>{" "}
                  {/* Edit */}
                  تعديل
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {" "}
                    {/* Edit */}
                    تعديل{" "}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  {/* Delete */}
                  حذف
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {" "}
                    {/* Delete */}
                    حذف
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickRemoveAccess(customerData);
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>{" "}
                  {/* Remove file access */}
                  إزالة الوصول إلى الملفات
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {" "}
                    {/* Remove file access  */}
                    إزالة الوصول إلى الملفات
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  //delete customer
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (customer: any) => {
    setUserId(customer.id);
    // setCustomer(customer);
    setDeleteModal(true);
  };
  const [removeAccessModal, setRemoveAccessModal] = useState<boolean>(false);

  const onClickRemoveAccess = (customer: any) => {
    setUserId(customer.id);
    setRemoveAccessModal(true);
  };

  const handleCustomerClicks = () => {
    validation.resetForm();
    setSingleCustomer({
      id: "",
      name: "",
      email: "",
      isAdmin: "",
    });
    setIsEdit(false);
    // // setCustomer("");
    toggle();
  };

  const handleDeleteCustomer = () => {
    if (userId) {
      deleteUser(
        accessToken,
        userId,
        setCustomer,
        logoutFunction,
        setUserId,
        setLoader
      );
    }
    setDeleteModal(false);
  };
  const handleRemoveFileAccess = () => {
    if (userId) {
      // deleteUser(
      //   accessToken,
      //   userId,
      //   setCustomer,
      //   logoutFunction,
      //   setUserId,
      //   setLoader
      // );
    }
    setDeleteModal(false);
  };
  useEffect(() => {
    if (accessToken) {
      getAllUsers(accessToken, setCustomer, logoutFunction, setLoader);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <RemoveAccessModal
        show={removeAccessModal}
        onClick={handleRemoveFileAccess}
        userId={userId}
        onCloseClick={() => {
          setRemoveAccessModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title=""
            // breadcrumbItem="Users"
            breadcrumbItem="المستخدمين  "
          />
          {loader === true ? (
            <div className="loader-box">
              <div className="loader"></div>
            </div>
          ) : (
            ""
          )}

          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={customer}
                  isGlobalFilter={true}
                  isAddButton={true}
                  isPagination={true}
                  isCustomPageSize={true}
                  handleUserClick={handleCustomerClicks}
                  buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                  // buttonName="Add User"
                  buttonName="إضافة مستخدم
                  "
                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                  pagination="pagination"
                  stateData={false}
                />
              </CardBody>
            </Card>
          </Col>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {/* {!!isEdit ? "Edit Customer" : "Add User"} */}
              {!!isEdit ? "تعديل المستخدم" : "إضافة مستخدم"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();

                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>
                        الاسم
                        {/* name */}
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        // placeholder="Insert name"
                        placeholder="دراج اسم"
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        {/* Email */}
                        البريد الإلكتروني
                      </Label>
                      <Input
                        name="email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        // placeholder="Insert user name"
                        placeholder="أدخل اسم المستخدم"
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                        disabled={isEdit ? true : false}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        {/* Role */}
                        نوع المستخدم
                      </Label>
                      <Input
                        name="role"
                        type="select"
                        className="form-select"
                        onChange={(e) =>
                          setSingleCustomer({
                            ...singleCustomer,
                            isAdmin: e.target.value === "Admin" ? "1" : "0",
                          })
                        }
                        value={
                          Number(singleCustomer.isAdmin) === 1
                            ? "Admin"
                            : "User"
                        }
                      >
                        {/* <option value="User">User</option>
                        <option value="Admin">Admin</option> */}
                        <option value="User">مستخدم</option>
                        <option value="Admin">مدير</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-customer"
                      >
                        {/* {!!isEdit ? "Save" : "Add"} */}
                        {!!isEdit ? "حفظ" : "إضافة"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
    ///
  );
};

export default EcommerceCustomers;
