import React from "react";
import { Link, useNavigate } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Row,
} from "reactstrap";

// import images
import logo from "../../assets/images/logo.svg";
import { useDispatch } from "react-redux";
import { resetPassword } from "slices/thunk";

const ResetPassword = () => {
  //meta title
  document.title = "Recover Password | ESignature";

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("الرجاء إدخال كلمة المرور لمرة واحدة"
      // "Please enter OTP"
      ),
      password: Yup.string().required("الرجاء ادخال كلمة المرور"
        // "Please enter password"
        ),
      password_confirmation: Yup.string().required("الرجاء تأكيد كلمة المرور"
        // "Please enter confirm password"
      ),
    }),
    onSubmit: async (values: any) => {
      try {
        const response = await dispatch(resetPassword(values));

        if (response.type === "user/resetPassword/fulfilled") {
          navigate("/login");
        }
      } catch (error: any) {
        console.log("Error while reset password : ", error);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle-self">
                  <Row>
                    <Col xs={7}>
                      <div className="bg-layout-text p-4">
                        <h5 className="bg-layout-text"> 
                        {/* Reset Password */}
                        إعادة تعيين كلمة المرور
                        </h5>
                        <p>
                          {/* Reset Password with ESignature */}
                          ESignature  إعادة تعيين كلمة المرور مع 
                          </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Input
                          name="code"
                          className="form-control"
                          // placeholder="Enter code"
                          placeholder="ادخل الرمز"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.code || ""}
                          invalid={
                            validation.touched.code && validation.errors.code
                              ? true
                              : false
                          }
                        />
                        {validation.touched.code && validation.errors.code ? (
                          <FormFeedback type="invalid">
                            {validation.errors.code}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Input
                          name="password"
                          className="form-control"
                          // placeholder="Enter password"
                          placeholder="ادخل كلمة المرور"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Input
                          name="password_confirmation"
                          className="form-control"
                          // placeholder="Confirm Password"
                          placeholder="تأكيد كلمة المرور"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password_confirmation || ""}
                          invalid={
                            validation.touched.password_confirmation &&
                            validation.errors.password_confirmation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password_confirmation &&
                        validation.errors.password_confirmation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password_confirmation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md " type="submit">
                          {/* Reset Password */}
                          إعادة تعيين كلمة المرور
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {/* Remember It ?{" "} */}
                  تذكر ذلك ؟
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    {/* Sign In here */}
                    تسجيل الدخول هنا
                  </Link>{" "}
                </p>
                {/* <p>© {new Date().getFullYear()} ESignature</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
