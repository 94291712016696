import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword, loginUser, logoutUser, changePassword } from "./thunk";

export const initialState = {
  user: {},
  error: "", // for error msg
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
  accessToken: "",
  userSigned: 0,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logOutToken: (state, action) => {
      state.accessToken = "";
      state.user = {};
    },

    updateUserData: (state, action) => {
      state.user = action.payload.user_data;
    },
    assignedUserSigned: (state, action) => {
      state.userSigned = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.user = payload.user_data;
      state.loading = false;
      state.accessToken = payload.access_token;
    });

    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.user = {};
      state.accessToken = "";
      state.loading = false;
    });

    builder.addCase(logoutUser.pending, (state, { payload }) => {});
    builder.addCase(logoutUser.fulfilled, (state, { payload }) => {
      state.accessToken = "";
      state.isUserLogout = true;
      state.user = {};
    });
    builder.addCase(logoutUser.rejected, (state, { payload }) => {});

    //forgot password
    builder.addCase(forgotPassword.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(changePassword.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.loading = false;
    });

    //reset password
  },
});
export const { logOutToken, updateUserData, assignedUserSigned } =
  loginSlice.actions;
export default loginSlice.reducer;
