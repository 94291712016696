import axios from "axios";
import { toast } from "react-toastify";

export const savePdfwithSignature = async (
  accessToken: any,
  pdfData: any,
  userData: any,
  allSignatureData: any,
  setLoader: any,
  logoutFunction: any,
  boxState: any,
  boxSize: any,
  textState: any,
  textSize: any,
  navigate: any,
  enable_hierarchy: boolean,
  pageNumberForm: any,
  pageIndex: any,
  userIdData: any,
  order: any,
  folderId: any,
  signatureTypeInfo: any
) => {
  try {
    const orderList: any = [];
    setLoader(true);
    for (let i: number = 0; i < userData.length; i++) {
      orderList.push(i + 1);
    }
    const formData = new FormData();
    formData.append("file", pdfData);
    formData.append("assigned_user_id", userIdData);
    formData.append("order_by_num", order);
    formData.append("page_index", pageNumberForm);
    formData.append("box_position", JSON.stringify(boxState));
    formData.append("box_size", JSON.stringify(boxSize));
    formData.append("enable_hierarchy", enable_hierarchy ? "1" : "0");
    formData.append("text_position", JSON.stringify(textState));
    formData.append("text_size", JSON.stringify(textSize));
    formData.append("signature_type", signatureTypeInfo);
    if (folderId && folderId !== undefined) {
      formData.append("folder_id", folderId);
    }
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/assign-user-esignature`,
      formData,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    setLoader(false);
    navigate("/assign-pdf");
    toast.success("User Assign successfully for Signready");
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      logoutFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const getAssignedUser = async (
  accessToken: any,

  id: String,
  setAllasignees: any,
  setLoader: any,
  logoutFunction: any,
  setPdfData: any,
  setStateData: any
) => {
  try {
    setLoader(true);
    const assignyPdf = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/view-assign-user-esignature/${id}`,

      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    setAllasignees(assignyPdf?.data?.data);
    setPdfData(assignyPdf?.data?.data?.get_document[0].pdf_base64);
    setStateData(true);

    setLoader(false);
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      logoutFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const saveAssignSignaturepdf = async (
  accessToken: any,
  setLoader: any,
  logoutFunction: any,
  pdf: any,
  assign_id: any,
  document_id: any,
  navigate: any,
  ipAddress: any
) => {
  try {
    setLoader(true);
    const pdfSaved = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/complete-user-esignature`,
      {
        pdf_encrypt: pdf,
        assign_id: assign_id,
        document_id: document_id,
        ip: ipAddress ? ipAddress : "00.00.0.000",
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    setLoader(false);
    toast.success(pdfSaved?.data?.message);
    navigate("/assign-pdf");
  } catch (err: any) {
    setLoader(false);

    if (err?.response?.data?.status === 401) {
      logoutFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};

export const getAllSignaturePdf = async (
  token: any,
  setSignature: any,
  dataFunction: any,
  setLoader: any,
  sigRef: any,
  setImage: any,
  setTest: any
) => {
  try {
    setLoader(true);

    const allSignature = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/get-pad-signature`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (allSignature?.data?.data.length !== 0) {
      setImage(allSignature?.data?.data[0].image);
      setTest(true);

      // if (sigRef?.current) {
      //   sigRef.current.fromDataURL(allSignature?.data?.data[0].image);
      // }
    } else {
      // toast.error("You have not existing signature");
      toast.error("ليس لديك توقيع موجود");
    }
    setLoader(false);
  } catch (err: any) {
    setLoader(false);
    console.log("error", err.message);

    if (err?.response?.data?.status === 401) {
      dataFunction();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    } else {
      toast.error("حدث خطأ ما");
    }
  }
};
